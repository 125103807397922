import {
    BRAND_AWAL,
    BRAND_SME,
    Identity,
    useIdentity,
} from '@theorchard/suite-frontend';
import {
    INSIGHTS_EMPLOYEE_OVERRIDE,
    INSIGHTS_SME_EMPLOYEE,
} from 'src/constants/featuresFlags';

export interface EmployeeIdentity extends Identity {
    /**
     * FF controlled property. Defines whether user is SME Employee or not.
     */
    isSmeEmployee?: boolean;
    isAwalEmployee?: boolean;
}

export const useEmployeeIdentity = (): EmployeeIdentity => {
    const identity = useIdentity();
    const isEmployee =
        identity.features[INSIGHTS_EMPLOYEE_OVERRIDE] || identity.isEmployee;
    const isSmeEmployee = identity.features[INSIGHTS_SME_EMPLOYEE]
        ? identity.isEmployee && identity.defaultBrand === BRAND_SME
        : false;
    const isAwalEmployee =
        identity.isEmployee && identity.defaultBrand === BRAND_AWAL;

    return {
        ...identity,
        isEmployee,
        isSmeEmployee,
        isAwalEmployee,
    };
};
