import { useMemo } from 'react';
import { ApolloClient, useQuery } from '@apollo/client';
import { selectTopChannels } from 'src/apollo/selectors';
import { useGdaEmployeeIdentity, usePrefetchQuery } from 'src/apollo/utils';
import { useDistributorsFilterOptions } from 'src/components/distributorFilter/utils';
// @TODO: Separate data layer from the presentation layer and avoid importing page (UI) logic into the data layer. Query arguments or moving constants can help achieve this separation.
import {
    DEFAULT_SORT_FIELD,
    DEFAULT_SORT_DIR,
    CHANNELS_PAGE_SIZE,
} from 'src/pages/catalog/pages/channels/constants';
import {
    TopChannelsQuery as Query,
    TopChannelsQueryVariables as QueryVars,
} from '../../definitions/TopChannelsQuery';
import topChannelsQuery from './topChannels.gql';

export const useTopChannelsCatalogQuery = (variables: QueryVars) => {
    const { data, loading, error } = useQuery<Query, QueryVars>(
        topChannelsQuery,
        { variables }
    );

    const memoizedData = useMemo(() => data && selectTopChannels(data), [data]);

    return {
        loading,
        data: memoizedData,
        error,
    };
};

export const usePrefetchTopChannelsQuery = (delay = 0) => {
    const { isEmployeeOrNotAwalGdaUser } = useGdaEmployeeIdentity();
    const { selectedValue } = useDistributorsFilterOptions();

    usePrefetchQuery<Query, QueryVars>(topChannelsQuery, {
        variables: {
            limit: CHANNELS_PAGE_SIZE,
            orderBy: DEFAULT_SORT_FIELD,
            orderDir: DEFAULT_SORT_DIR,
            countries: [],
            distributors: selectedValue,
            globalParticipantIds: [],
            labelIds: [],
            subaccountIds: [],
        },
        delay,
        skip: !isEmployeeOrNotAwalGdaUser,
    });
};

interface PrefetchQueryOptions {
    skip: boolean;
}

export const prefetchTopChannelsCatalogQuery = async (
    client: ApolloClient<object>,
    variables: Partial<QueryVars> = {},
    options: PrefetchQueryOptions = { skip: false }
) => {
    const { skip } = options;

    return (
        !skip &&
        (await client.query({
            query: topChannelsQuery,
            variables: {
                limit: CHANNELS_PAGE_SIZE,
                orderBy: DEFAULT_SORT_FIELD,
                orderDir: DEFAULT_SORT_DIR,
                countries: [],
                distributors: '',
                globalParticipantIds: [],
                labelIds: [],
                subaccountIds: [],
                ...variables,
            },
        }))
    );
};
