import React from 'react';
import { BRAND_AWAL, initApplication } from '@theorchard/suite-frontend';
import typePolicies from 'src/apollo/cache/typePolicies';
import typeDefs from 'src/apollo/typeDefs.gql';
import PreloadedDataProvider from 'src/app/preloadedDataProvider';
import SpotifyPlayerPlugin from 'src/app/spotifyPlayerPlugin';
import {
    INSIGHTS_ARTIST_PAGE_ENHANCEMENT,
    INSIGHTS_CHARTS_V2,
    INSIGHTS_NAVBAR_V2,
    INSIGHTS_NMF,
    INSIGHTS_PREFERENCES_PAGE,
    INSIGHTS_SOURCES_TABLE_POPUP_V2,
    INSIGHTS_TADAS,
    INSIGHTS_TIKTOK_SONGS_PAGE,
} from 'src/constants/featuresFlags';
import OrchardGoBannerPlugin from './app/orchardGoBannerPlugin';
import OverrideBrandColorsPlugin from './app/overrideBrandColorsPlugin';
import PersistedCacheInitPlugin from './app/persistedCachePlugin';
import ApplicationUrlPlugin from './app/urlProvider';
import CatalogRedirect from './components/catalogRedirect';
import HomeRedirect from './components/homeRedirect';
import {
    StoreStatusNavButton,
    StoreStatusNavIcon,
} from './components/storeStatus';
import {
    PROFILE_INSIGHTS,
    ROUTE_ACCOUNT,
    ROUTE_CATALOG,
    ROUTE_CATALOG_STRICT,
    ROUTE_CHANNEL,
    ROUTE_CHART,
    ROUTE_CHART_V2,
    ROUTE_CHARTS,
    ROUTE_COMPARE_PARTICIPANT,
    ROUTE_COMPARE_SONG,
    ROUTE_FAVORITES,
    ROUTE_GAINERS,
    ROUTE_HOME,
    ROUTE_NEW_RELEASES,
    ROUTE_PARTICIPANT,
    ROUTE_PREFERENCES,
    ROUTE_PRODUCT,
    ROUTE_SEARCH,
    ROUTE_SETTINGS,
    ROUTE_SONG,
    ROUTE_SUBACCOUNT,
    ROUTE_TRENDING,
    ROUTE_VIDEO,
} from './constants';

const AccountPage = React.lazy(async () => await import('src/pages/account'));
const ProductPage = React.lazy(async () => await import('src/pages/product'));
const SongPage = React.lazy(async () => await import('src/pages/song'));
const SongComparePage = React.lazy(
    async () => await import('src/pages/song/comparePage')
);
const GainersPage = React.lazy(async () => await import('src/pages/gainers'));
const TrendingPage = React.lazy(
    async () => await import('src/pages/gainers/trendingPage')
);
const NewReleasesPage = React.lazy(
    async () => await import('src/pages/newReleases')
);
const VideoPage = React.lazy(async () => await import('src/pages/video'));
const SettingsPage = React.lazy(async () => await import('src/pages/settings'));
const PreferencesPage = React.lazy(
    async () => await import('src/pages/preferences')
);
const SearchPageES = React.lazy(async () => await import('src/pages/searchES'));
const CatalogPage = React.lazy(async () => await import('src/pages/catalog'));
const ChannelPage = React.lazy(async () => await import('src/pages/channel'));
const ChartPage = React.lazy(async () => await import('src/pages/chart'));
const ChartPageV2 = React.lazy(
    async () => await import('src/pages/chart/chartPageV2')
);
const ChartsPage = React.lazy(async () => await import('src/pages/charts'));
const FavoritesPage = React.lazy(
    async () => await import('src/pages/favorites')
);
const ParticipantPage = React.lazy(
    async () => await import('src/pages/participant')
);
const ParticipantComparePage = React.lazy(
    async () => await import('src/pages/participant/participantComparePage')
);

const ParticipantComparePageV2 = React.lazy(
    async () => await import('src/pages/participant/comparePage')
);

initApplication({
    title: 'Insights',
    profileType: PROFILE_INSIGHTS,
    featureFlagFilter: 'insights_|show_sme_data',
    config: {
        auth0StoreTokenInClient: true,
    },
    colorScheme: {
        V2: () => true,
    },
    apollo: {
        typePolicies,
        typeDefs,
        defaultOptions: {
            watchQuery: {
                fetchPolicy: 'cache-and-network',
                nextFetchPolicy: 'cache-first',
            },
        },
    },
    mainNav: {
        collapsible: {
            featureFlags: [INSIGHTS_NAVBAR_V2],
        },
        enableZendesk: {
            hasAccess: ({ identity }) => identity?.defaultBrand === BRAND_AWAL,
        },
        sections: [
            {
                id: 'general',
                items: [
                    {
                        term: 'navigation.search',
                        path: ROUTE_SEARCH,
                        navIcon: 'SearchNavIcon',
                        match: /^\/search.*$/,
                    },
                    {
                        term: 'navigation.catalog',
                        path: ROUTE_CATALOG,
                        navIcon: 'CatalogNavIcon',
                        match: /^\/catalog.*$/,
                    },
                    {
                        term: 'navigation.favorites',
                        path: ROUTE_FAVORITES,
                        navIcon: 'FavoritesNavIcon',
                        match: /^\/favorites.*$/,
                    },
                    {
                        term: 'navigation.charts',
                        path: ROUTE_CHARTS,
                        navIcon: 'ChartsNavIcon',
                        match: /^\/chart?.*$/,
                    },
                    {
                        term: 'navigation.gainers',
                        path: ROUTE_GAINERS,
                        navIcon: 'TrendingSongsNavIcon',
                        match: /^\/gainers.*$/,
                        hasAccess: ({ identity: { features, isEmployee } }) => {
                            if (features[INSIGHTS_TADAS]) return false;
                            return (
                                features[INSIGHTS_TIKTOK_SONGS_PAGE] &&
                                isEmployee
                            );
                        },
                    },
                    {
                        term: 'navigation.trending',
                        path: ROUTE_TRENDING,
                        navIcon: 'TrendingSongsNavIcon',
                        match: /^\/trending.*$/,
                        hasAccess: ({ identity: { features, isEmployee } }) =>
                            features[INSIGHTS_TADAS] && isEmployee,
                    },
                    {
                        term: 'navigation.newReleases',
                        path: ROUTE_NEW_RELEASES,
                        navIcon: 'NewReleasesNavIcon',
                        match: /^\/newReleases.*$/,
                        hasAccess: ({ identity: { features, isEmployee } }) =>
                            features[INSIGHTS_NMF] && isEmployee,
                    },
                ],
            },
            {
                id: 'secondary',
                type: 'secondary',
                items: [
                    {
                        term: 'sources.modal.storeStatus',
                        path: '#store-sources',
                        icon: <StoreStatusNavIcon />,
                        Component: StoreStatusNavButton,
                        featureFlags: [INSIGHTS_SOURCES_TABLE_POPUP_V2],
                    },
                    {
                        term: 'navigation.preferences',
                        path: ROUTE_PREFERENCES,
                        navIcon: 'PreferencesNavIcon',
                        match: /^\/preferences.*$/,
                        featureFlags: [
                            INSIGHTS_PREFERENCES_PAGE,
                            INSIGHTS_NAVBAR_V2,
                        ],
                    },
                ],
            },
        ],
        footer: {
            items: [
                {
                    term: 'account.settings',
                    path: ROUTE_SETTINGS,
                    hasAccess: ({ identity: { features } }) =>
                        !features[INSIGHTS_PREFERENCES_PAGE] ||
                        !features[INSIGHTS_NAVBAR_V2],
                },
            ],
        },
    },
    routes: [
        { path: ROUTE_HOME, page: HomeRedirect },
        { path: ROUTE_SEARCH, page: SearchPageES },
        {
            path: ROUTE_CHART_V2,
            page: ChartPageV2,
            exact: true,
            featureFlags: [INSIGHTS_CHARTS_V2],
        },
        { path: ROUTE_CHART, page: ChartPage },
        { path: ROUTE_SONG, page: SongPage },
        { path: ROUTE_ACCOUNT, page: AccountPage },
        { path: ROUTE_SUBACCOUNT, page: AccountPage },
        { path: ROUTE_COMPARE_SONG, page: SongComparePage },
        { path: ROUTE_PARTICIPANT, page: ParticipantPage },
        {
            path: ROUTE_COMPARE_PARTICIPANT,
            page: ParticipantComparePage,
            hasAccess: ({ identity: { features } }) =>
                !features[INSIGHTS_ARTIST_PAGE_ENHANCEMENT],
        },
        {
            path: ROUTE_COMPARE_PARTICIPANT,
            page: ParticipantComparePageV2,
            featureFlags: [INSIGHTS_ARTIST_PAGE_ENHANCEMENT],
        },
        { path: ROUTE_PRODUCT, page: ProductPage },
        { path: ROUTE_CATALOG_STRICT, page: CatalogRedirect, exact: true },
        { path: ROUTE_CATALOG, page: CatalogPage },
        { path: ROUTE_VIDEO, page: VideoPage },
        { path: ROUTE_CHANNEL, page: ChannelPage },
        { path: ROUTE_FAVORITES, page: FavoritesPage },
        { path: ROUTE_CHARTS, page: ChartsPage },
        {
            path: ROUTE_SETTINGS,
            page: SettingsPage,
            hasAccess: ({ identity: { features } }) =>
                !features[INSIGHTS_PREFERENCES_PAGE] ||
                !features[INSIGHTS_NAVBAR_V2],
        },
        {
            path: ROUTE_PREFERENCES,
            page: PreferencesPage,
            featureFlags: [INSIGHTS_PREFERENCES_PAGE, INSIGHTS_NAVBAR_V2],
        },
        {
            path: ROUTE_GAINERS,
            page: GainersPage,
            hasAccess: ({ identity: { features, isEmployee } }) => {
                if (features[INSIGHTS_TADAS]) return false;
                return features[INSIGHTS_TIKTOK_SONGS_PAGE] && isEmployee;
            },
        },
        {
            path: ROUTE_TRENDING,
            page: TrendingPage,
            hasAccess: ({ identity: { features, isEmployee } }) =>
                features[INSIGHTS_TADAS] && isEmployee,
        },
        {
            path: ROUTE_NEW_RELEASES,
            page: NewReleasesPage,
            hasAccess: ({ identity: { features, isEmployee } }) =>
                features[INSIGHTS_NMF] && isEmployee,
        },
    ],
    plugins: [
        PersistedCacheInitPlugin,
        PreloadedDataProvider,
        SpotifyPlayerPlugin,
        OrchardGoBannerPlugin,
        OverrideBrandColorsPlugin,
        ApplicationUrlPlugin,
    ],
});
