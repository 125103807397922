import { GridTable as SuiteGridTable } from '@theorchard/suite-components';

const VoidComponent = () => null;

SuiteGridTable.addExtension({
    name: 'placeholder-column',
    isEnabled: props => Boolean(props.showPlaceholderColumn),
    onPropsInit: props => ({
        ...props,
        columnDefs: [
            ...props.columnDefs,
            {
                name: 'placeholder-column',
                minWidth: 'min-content',
                maxWidth: '1fr',
                title: '',
                align: 'right',
                separator: false,
                configurable: false,
                visibility: 'visible',

                Cell: VoidComponent,
                LoadingIndicator: VoidComponent,
                HeaderLoadingIndicator: VoidComponent,
            },
        ],
    }),
});
