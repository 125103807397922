import { ErrorType, ErrorTypeOptions } from 'src/components/errorV2/types';

const getErrorType = (
    error: Error | undefined,
    loading: boolean,
    data?: object[] | null,
    filteredData?: object[],
    isCatalog = false
): ErrorType | null => {
    if (loading) return null;

    const hasError = Boolean(error);
    if (hasError) return 'dataError';

    const isNoData = data ? data.length === 0 : true;
    if (isNoData) return 'noData';

    const isNoFilteredData = filteredData?.length === 0;
    if (isCatalog && isNoFilteredData) return 'noResultsInCatalog';
    if (isNoFilteredData) return 'noResults';

    return null;
};

export const tryGetErrorType = ({
    isError,
    isLoading,
    hasData,
    hasFilteredData,
    isInCatalog,
}: ErrorTypeOptions): ErrorType | null => {
    if (isLoading) return null;
    if (isError) return 'dataError';
    if (hasData === false) return 'noData';
    if (hasFilteredData === false)
        return isInCatalog ? 'noResultsInCatalog' : 'noResults';
    return null;
};

export default getErrorType;
