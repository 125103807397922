import { minBy } from 'lodash';
import { Account } from 'src/components/accountsDetailsPopup';
import { EMPTY_CHAR } from 'src/constants';
import { GlobalSoundRecordingSearchES } from '../definitions/GlobalSoundRecordingSearchES';
import { Entity } from './types';
import {
    isSMEProduct,
    selectAccounts,
    selectImageUrl,
    toEntity,
} from './utils';

export interface SoundRecordingSearchResultItem {
    id: string;
    isrc: string;
    name: string;
    imageUrl?: string | null;
    participants: Entity[];
    isPartOfCatalog: boolean;
    accounts?: Account[] | null;
    releaseDate?: string | null;
}
export interface SoundRecordingSearchResult {
    totalCount: number;
    items: SoundRecordingSearchResultItem[];
}

export const selectGlobalSoundRecordingSearchESResults = (
    data: GlobalSoundRecordingSearchES,
    shouldUseGSRName = false
): SoundRecordingSearchResult => ({
    totalCount: data.globalSoundRecordingSearchES.totalCount,
    items: data.globalSoundRecordingSearchES.items.map(({ item, score }) => {
        const labelNames = item.labels?.length
            ? item.labels.map(l => l.vendorName).join(', ')
            : item.imprint;
        const accounts = item?.globalSoundRecording?.catalogLabelsV2
            ? selectAccounts(
                  item.globalSoundRecording.catalogLabelsV2 as [],
                  labelNames
              )
            : null;
        const productsWithoutDeletions =
            item.globalSoundRecording?.catalogProducts?.filter(
                product => product?.deletions === 'false'
            );
        const earliestProductWithoutDeletions = minBy(
            productsWithoutDeletions,
            item => item?.releaseDate && Date.parse(item?.releaseDate)
        );
        const imageUrl = selectImageUrl(
            item?.imageUrl,
            earliestProductWithoutDeletions?.imageLocation
                ? earliestProductWithoutDeletions.imageLocation
                : item.globalSoundRecording?.catalogProducts?.[0]
                      ?.imageLocation,
            isSMEProduct(
                item.globalSoundRecording?.catalogProducts?.[0]
                    ?.notForDistribution
            )
        );

        const songName = shouldUseGSRName
            ? item.globalSoundRecording.name
            : item.name;

        return {
            id: item.id,
            imageUrl,
            isPartOfCatalog: item.isPartOfCatalog,
            isrc: item.isrc,
            name: songName,
            participants: item.participants.map(toEntity) ?? [],
            popularity: item.popularity,
            releaseDate: item.releaseDate,
            score,
            spotifyId: item.socialAccountIds?.spotify,
            streams28Days: item.usageStats?.streams28Days,
            streams7Days: item.usageStats?.streams7Days,
            streamsAllTime: item.usageStats?.streamsAllTime,
            labelNames: labelNames || EMPTY_CHAR,
            accounts,
        };
    }),
});
