import React from 'react';
import { HelpTooltip, Modal } from '@theorchard/suite-components';
import { formatMessage } from '@theorchard/suite-frontend';
import StoreStatusGridTable from './storeStatusGridTable';

const CLASSNAME = 'StoreStatusModal';
const CLASSNAME_HEADER = `${CLASSNAME}-header`;
const CLASSNAME_HEADER_TOOLTIP = `${CLASSNAME_HEADER}-tooltip`;

export interface Props {
    isOpen: boolean;
    onClose: VoidFunction;
}

const StoreStatusModal: React.FC<Props> = ({ isOpen, onClose }) => (
    <Modal
        isOpen={isOpen}
        onRequestClose={onClose}
        title={
            <div className={CLASSNAME_HEADER}>
                <h3 className="m-0">
                    {formatMessage('sources.modal.storeStatus')}
                </h3>
                <HelpTooltip
                    id="storeStatusTooltip"
                    message={formatMessage('sources.modal.tooltip')}
                    overlayClassName={CLASSNAME_HEADER_TOOLTIP}
                    size={16}
                />
            </div>
        }
        portalClassName={CLASSNAME}
    >
        <StoreStatusGridTable />
    </Modal>
);

export default StoreStatusModal;
