import React, { FC, useCallback, useState } from 'react';
import { ErrorStatusContext } from '../../context';
import type { DataStatus } from '../../types';

const ErrorProvider: FC = ({ children }) => {
    const [dataStatuses, setDataStatusInternal] = useState<
        Record<string, DataStatus>
    >({});

    const setDataStatus = useCallback<
        (name: string, value: DataStatus) => void
    >((name, value) => {
        setDataStatusInternal(prevValue => ({ ...prevValue, [name]: value }));
    }, []);

    return (
        <ErrorStatusContext.Provider value={{ dataStatuses, setDataStatus }}>
            {children}
        </ErrorStatusContext.Provider>
    );
};

export default ErrorProvider;
