import React from 'react';
import { GridTable } from '@theorchard/suite-components';
import { createHref, useFeatureFlag } from '@theorchard/suite-frontend';
import { Link } from 'react-router-dom';
import PlatformIcon from 'src/components/platformIcon';
import TruncatedText from 'src/components/truncatedText';
import { ROUTE_CHART, ROUTE_CHART_V2 } from 'src/constants';
import { getDefinition } from 'src/constants/charts';
import { INSIGHTS_CHARTS_V2 } from 'src/constants/featuresFlags';

GridTable.defineColumnTemplate('chartLink', {
    align: 'left',
    Cell: ({ data }) => {
        const isChartV2Enabled = useFeatureFlag(INSIGHTS_CHARTS_V2);
        const chart = data.chart?.definition ?? getDefinition(data);
        const href = isChartV2Enabled
            ? createHref(ROUTE_CHART_V2, {
                  platform: chart.platform,
                  type: chart.type,
                  target: chart.target,
                  frequency: chart.frequency,
                  genre: chart.genre,
                  country: data.country,
              })
            : createHref(ROUTE_CHART, {
                  id: chart.key,
                  country: data.country,
              });

        return (
            <Link
                className="ChartLinkCell d-inlineflex width100 align-items-center"
                to={href}
                onClick={e => e.stopPropagation()}
            >
                <PlatformIcon platform={chart.platform} />
                <TruncatedText value={chart.label} />
            </Link>
        );
    },
});
