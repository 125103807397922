import React from 'react';
import { GridTable, Tooltip } from '@theorchard/suite-components';
import { formatMessage } from '@theorchard/suite-frontend';
import { GlyphIcon, GlyphName16PX } from '@theorchard/suite-icons';
import { TADASStayingPower } from 'src/apollo/definitions/globalTypes';
import { formatPercentage, normalizeNumber } from 'src/utils';

const CLASSNAME = 'TrendingCell';

interface CellOptions {
    title: string;
    iconName: GlyphName16PX;
}

GridTable.defineColumnTemplate('trending', {
    align: 'left',
    Cell: ({ value, data: { stayingPowerScore } }) => {
        const score = normalizeNumber(stayingPowerScore);
        let options: CellOptions | null;

        switch (value) {
            case TADASStayingPower.LOW:
                options = {
                    iconName: 'trendingLow',
                    title: formatMessage('trending.low'),
                };
                break;
            case TADASStayingPower.MEDIUM:
                options = {
                    iconName: 'trendingMedium',
                    title: formatMessage('trending.medium'),
                };
                break;
            case TADASStayingPower.HIGH:
                options = {
                    iconName: 'trendingHigh',
                    title: formatMessage('trending.high'),
                };
                break;
            default:
                options = null;
                break;
        }

        if (options === null) return null;
        if (score === null)
            return (
                <span className={CLASSNAME}>
                    <GlyphIcon name={options.iconName} size={16} />
                    {options.title}
                </span>
            );

        return (
            <Tooltip
                id="trendingTooltip"
                className={CLASSNAME}
                message={`${options.title}: ${formatPercentage(score)}`}
                placement="bottom"
            >
                <GlyphIcon name={options.iconName} size={16} />
                {options.title}
            </Tooltip>
        );
    },
});
