import React, { FC } from 'react';
import { Button, InfoMessage } from '@theorchard/suite-components';
import { formatMessage } from '@theorchard/suite-frontend';
import { CLASSNAME as ERROR_CLASSNAME } from 'src/components/errorV2/error';
import { SharedErrorV2Props } from 'src/components/errorV2/types';

const NoResultsInCatalog: FC<SharedErrorV2Props> = ({
    actionButtonHandler,
    actionButtonText,
    mode,
    message,
}) => {
    if (mode === 'tinySection') {
        return (
            <>
                {message ??
                    formatMessage('error.noResultsInCatalogCheck.message')}
            </>
        );
    }

    return (
        <InfoMessage
            illustration="noResults"
            message={formatMessage('error.noResultsInCatalog.title')}
            size={mode === 'section' ? 'sm' : 'lg'}
            body={
                <>
                    <span>
                        {message ??
                            formatMessage(
                                'error.noResultsInCatalogCheck.message'
                            )}
                    </span>
                    {actionButtonHandler && (
                        <Button
                            variant="secondary"
                            onClick={actionButtonHandler}
                            className={`${ERROR_CLASSNAME}-actionBtn`}
                        >
                            {actionButtonText ??
                                formatMessage(
                                    'error.noResultsInCatalogCheck.button'
                                )}
                        </Button>
                    )}
                </>
            }
        />
    );
};

export default NoResultsInCatalog;
