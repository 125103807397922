import React, { FC } from 'react';
import { InfoMessage } from '@theorchard/suite-components';
import { formatMessage } from '@theorchard/suite-frontend';
import { SharedErrorV2Props } from 'src/components/errorV2/types';

const NotFound: FC<SharedErrorV2Props> = ({ mode, title, message }) => (
    <InfoMessage
        illustration="notFound"
        message={title ?? formatMessage('error.pageNotFound.title')}
        body={message ?? formatMessage('error.pageNotFound.message')}
        size={mode === 'section' ? 'sm' : 'lg'}
    />
);

export default NotFound;
