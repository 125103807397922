import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { formatDate, useFeatureFlag } from '@theorchard/suite-frontend';
import dayjs, { Dayjs } from 'dayjs';
import { groupBy, minBy, orderBy, values } from 'lodash';
import { DATE_FORMAT } from 'src/constants';
import { INSIGHTS_SOURCES_TABLE_POPUP_V2 } from 'src/constants/featuresFlags';
import {
    Source,
    SourceType,
    STORE_ID_APPLE_MUSIC,
    STORE_ID_SPOTIFY,
    STORE_ID_TIKTOK,
    STORE_STATUS_ORDER,
} from 'src/constants/stores';
import { selectSourcesByTypes } from 'src/utils/sources';
import { AnalyticsFeedsStatus } from '../definitions/AnalyticsFeedsStatus';
import { Distributor } from '../definitions/globalTypes';
import { filterData, usePrefetchQuery } from '../utils';
import AnalyticsFeedsStatusQuery from './analyticsFeedsStatus.gql';

export const STORE_ID_ITUNES = 9999;
export const STORE_ID_YOUTUBE_CLAIM = 45302;
export const EXCLUDED_STORES_IDS = [STORE_ID_YOUTUBE_CLAIM, STORE_ID_ITUNES];
const STORE_ID_AMAZON_DOWNLOADS = 16;
const EXCLUDED_FEEDS = [STORE_ID_AMAZON_DOWNLOADS];
const ANALYTICS_SOURCES = [
    STORE_ID_SPOTIFY,
    STORE_ID_APPLE_MUSIC,
    STORE_ID_TIKTOK,
];

interface Options {
    types?: SourceType[];
    skip?: boolean;
}

export const useAnalyticsFeedsStatus = ({ types, skip }: Options = {}) => {
    const { data, loading, error } = useQuery<AnalyticsFeedsStatus>(
        AnalyticsFeedsStatusQuery,
        { skip }
    );

    const storesStatus = useMemo(() => {
        const rawFeeds = filterData(data?.analyticFeedStatuses.feedStatus);
        const feedsData = rawFeeds
            // Filter out Feeds excluded from statuses calculations
            .filter(({ feed }) => !EXCLUDED_FEEDS.includes(feed.id))
            // Transform Feeds to Stores Sources
            .map<Source>(storeData => {
                const {
                    feed: {
                        store: { storeId, storeName },
                    },
                    storeHighWaterMark,
                    updateStatus,
                    missingDatesBeforeStoreHighWatermark: missingDates,
                } = storeData;
                const latestDate = formatDate(
                    dayjs.utc(storeHighWaterMark),
                    DATE_FORMAT
                );

                return {
                    storeId,
                    storeName,
                    latestDate,
                    updateStatus,
                    missingDates:
                        missingDates.length > 0 ? missingDates : undefined,
                };
            })
            // Filter out stores that are not present on Insights Portal
            .filter(({ storeId }) => !EXCLUDED_STORES_IDS.includes(storeId));

        const sourcesById = groupBy(feedsData, 'storeId');
        const feedsByEarliestDate = values(sourcesById).reduce<Source[]>(
            (acc, feedsByStoreId) => {
                const feedsWithMissingDates = feedsByStoreId.filter(
                    ({ missingDates }) => missingDates
                );

                const selectedFeeds =
                    feedsWithMissingDates.length > 0
                        ? feedsWithMissingDates
                        : feedsByStoreId;

                const feedWithEarliestDate = minBy(selectedFeeds, 'latestDate');

                return feedWithEarliestDate
                    ? [...acc, feedWithEarliestDate]
                    : acc;
            },
            []
        );

        const supportedSources = types
            ? selectSourcesByTypes(feedsByEarliestDate, types)
            : feedsByEarliestDate;

        return orderBy(
            supportedSources,
            [
                ({ storeId }) =>
                    STORE_STATUS_ORDER.includes(storeId)
                        ? STORE_STATUS_ORDER.indexOf(storeId)
                        : STORE_STATUS_ORDER.length,
                'storeName',
            ],
            ['asc', 'asc']
        );
    }, [data, types]);

    return {
        data: storesStatus,
        loading,
        error,
    };
};

export const useAnalyticsLatestDate = () => {
    const { data, loading, error } = useQuery<AnalyticsFeedsStatus>(
        AnalyticsFeedsStatusQuery,
        { fetchPolicy: 'cache-first' }
    );

    const earliestDate = useMemo(() => {
        const feedStatuses = filterData(data?.analyticFeedStatuses.feedStatus);

        if (feedStatuses.length === 0) return undefined;

        const analyticSources = feedStatuses
            .filter(
                ({
                    feed: {
                        store: { storeId },
                    },
                }) => ANALYTICS_SOURCES.includes(storeId)
            )
            .filter(
                ({ distributor }) =>
                    distributor === Distributor.SME ||
                    distributor === Distributor.THEORCHARD
            );

        const latestDate = analyticSources.reduce<Dayjs | undefined>(
            (result, { storeHighWaterMark }) => {
                if (!storeHighWaterMark) return result;

                const date = dayjs(storeHighWaterMark);
                if (!date.isValid()) return result;
                if (!result) return date;

                return date.isBefore(result) ? date : result;
            },
            undefined
        );

        return latestDate;
    }, [data]);

    return {
        data: earliestDate ? formatDate(earliestDate, DATE_FORMAT) : undefined,
        loading,
        error,
    };
};

export const usePrefetchAnalyticsFeedsStatusQuery = () => {
    const isStoreV2Enabled = useFeatureFlag(INSIGHTS_SOURCES_TABLE_POPUP_V2);
    usePrefetchQuery(AnalyticsFeedsStatusQuery, {
        variables: {},
        skip: !isStoreV2Enabled,
    });
};
