import { useCallback, useContext } from 'react';
import { ErrorStatusContext } from '../context';
import type { DataStatus, DataStatusResult } from '../types';

export const useErrorContext = (name = 'default'): DataStatusResult => {
    const {
        dataStatuses: { [name]: dataStatus },
        setDataStatus: setDataStatusInternal,
    } = useContext(ErrorStatusContext);
    const setDataStatus = useCallback(
        (value: DataStatus) => {
            setDataStatusInternal?.(name, value);
        },
        [name]
    );

    return {
        dataStatus,
        setDataStatus,
    };
};
