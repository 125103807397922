import React, { FC, ReactNode } from 'react';
import { getShortPeriodName, type AbsolutePeriod } from 'src/utils/datePeriod';

export interface Props {
    testId?: string;
    period?: AbsolutePeriod;
    title?: ReactNode;
    metric: ReactNode;
}

const CLASSNAME = 'DatePeriodHeaderCell';

const DatePeriodHeaderCell: FC<Props> = ({
    period,
    title,
    metric,
    testId = CLASSNAME,
}) => (
    <div className={CLASSNAME} data-testid={testId}>
        {title ?? getShortPeriodName(period)}
        <span>{metric}</span>
    </div>
);

export default DatePeriodHeaderCell;
