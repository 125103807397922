import { useFeatureFlag } from '@theorchard/suite-frontend';
import { INSIGHTS_ERROR_PAGE } from 'src/constants/featuresFlags';
import type { ErrorTypeOptions, ErrorTypeResult } from '../types';
import { tryGetErrorType } from './getErrorType';

export const useErrorType = (options: ErrorTypeOptions): ErrorTypeResult => {
    const isErrorV2Enabled = useFeatureFlag(INSIGHTS_ERROR_PAGE);
    const errorType = isErrorV2Enabled ? tryGetErrorType(options) : null;

    return {
        errorType,
    };
};
