import { useMemo } from 'react';
import { ApolloClient, useQuery } from '@apollo/client';
import { selectTopVideos } from 'src/apollo/selectors';
import { useGdaEmployeeIdentity } from 'src/apollo/utils';
import { useDistributorsFilterOptions } from 'src/components/distributorFilter/utils';
import {
    VIDEOS_PAGE_SIZE,
    DEFAULT_SORT_FIELD,
    DEFAULT_SORT_DIR,
} from 'src/pages/catalog/pages/videos/constants';
import {
    TopVideosQuery as Query,
    TopVideosQueryVariables as QueryVars,
} from '../../definitions/TopVideosQuery';
import { usePrefetchQuery } from '../../utils';
import topVideosQuery from './topVideos.gql';

interface QueryOptions {
    skip?: boolean;
}

export const useTopVideosCatalogQuery = (
    variables: QueryVars,
    { skip }: QueryOptions = {}
) => {
    const { data, loading, error } = useQuery<Query, QueryVars>(
        topVideosQuery,
        { variables, skip }
    );

    const memoizedData = useMemo(() => data && selectTopVideos(data), [data]);

    return {
        loading,
        data: memoizedData,
        error,
    };
};

export const usePrefetchTopVideosQuery = (delay = 0) => {
    const { isEmployeeOrNotAwalGdaUser } = useGdaEmployeeIdentity();
    const { selectedValue } = useDistributorsFilterOptions();

    usePrefetchQuery<Query, QueryVars>(topVideosQuery, {
        variables: {
            limit: VIDEOS_PAGE_SIZE,
            orderBy: DEFAULT_SORT_FIELD,
            orderDir: DEFAULT_SORT_DIR,
            channelIds: [],
            countries: [],
            distributors: selectedValue,
            globalParticipantIds: [],
            labelIds: [],
            subaccountIds: [],
            trackTypes: [],
        },
        delay,
        skip: !isEmployeeOrNotAwalGdaUser,
    });
};

interface PrefetchQueryOptions {
    skip: boolean;
}

export const prefetchTopVideosCatalogQuery = async (
    client: ApolloClient<object>,
    variables: Partial<QueryVars> = {},
    options: PrefetchQueryOptions = { skip: false }
) => {
    const { skip } = options;

    return (
        !skip &&
        (await client.query({
            query: topVideosQuery,
            variables: {
                limit: VIDEOS_PAGE_SIZE,
                orderBy: DEFAULT_SORT_FIELD,
                orderDir: DEFAULT_SORT_DIR,
                channelIds: [],
                countries: [],
                globalParticipantIds: [],
                labelIds: [],
                subaccountIds: [],
                trackTypes: [],
                ...variables,
            },
        }))
    );
};
