import { filterData } from '@theorchard/suite-frontend';
import { GlobalSoundRecordingTadasTrends as TadasTrends } from 'src/apollo/definitions/GlobalSoundRecordingTadasTrends';
import type { Account } from 'src/components/accountsDetailsPopup';
import { TADASStayingPower } from '../definitions/globalTypes';
import { Entity } from './types';
import { selectAccounts, toEntity } from './utils';

export interface TrendingSong {
    id: string;
    accounts?: Account[];
    isrc: string;
    songName?: string;
    imageUrl?: string | null;
    participants?: Entity[] | null;
    market?: string;
    daysTrending?: number;
    stayingPower: TADASStayingPower;
    stayingPowerScore: number;
    trendingStartDate?: string;
    appleMusicStreams1Day?: number;
    spotifyStreams1Day?: number;
    releaseDate?: string | null;
}

export const selectGlobalSoundRecordingTadasTrends = (data: TadasTrends) => {
    const {
        globalSoundRecordingTadasTrends: { totalCount, trends },
    } = data;

    const items: TrendingSong[] = trends.map(
        ({
            id,
            globalSoundRecording: {
                isrc,
                name,
                imageUrl,
                catalogLabelsV2,
                globalParticipants,
                labelName,
                imprint,
                releaseDateV2,
            },
            analytics: { appleMusicStreams1Day, spotifyStreams1Day },
            daysTrending,
            market,
            stayingPower,
            stayingPowerScore,
            trendingStartDate,
        }) => {
            return {
                id,
                isrc,
                imageUrl,
                imprint,
                songName: name,
                releaseDate: releaseDateV2,
                participants: filterData(globalParticipants).map(toEntity),
                accounts: selectAccounts(catalogLabelsV2, labelName),
                daysTrending,
                market,
                appleMusicStreams1Day,
                spotifyStreams1Day,
                stayingPower,
                stayingPowerScore,
                trendingStartDate,
            };
        }
    );

    return {
        totalCount,
        items,
    };
};
