import React, { FC } from 'react';
import { InfoMessage } from '@theorchard/suite-components';
import { formatMessage } from '@theorchard/suite-frontend';
import { SharedErrorV2Props } from 'src/components/errorV2/types';

const NoData: FC<SharedErrorV2Props> = ({ mode, title, message }) => (
    <InfoMessage
        message={title ?? formatMessage('error.noData.subtext')}
        body={message ?? formatMessage('error.noDataYet.message')}
        size={mode === 'section' ? 'sm' : 'lg'}
    />
);

export default NoData;
