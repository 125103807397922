import React, { MouseEventHandler, useState } from 'react';
import { MainNav, MainNavLinkOptions } from '@theorchard/suite-frontend';
import { trackStoreStatusEngagement } from 'src/utils/segment';
import StoreStatusModal from './storeStatusModal';

const StoreSourcesNavButton: React.FC<MainNavLinkOptions> = props => {
    const [isOpen, setIsOpen] = useState(false);

    const handleClick: MouseEventHandler = event => {
        setIsOpen(true);
        event.preventDefault();
        event.stopPropagation();
        trackStoreStatusEngagement();
    };

    return (
        <>
            <MainNav.NavLink {...props} onClick={handleClick} />
            <StoreStatusModal
                isOpen={isOpen}
                onClose={() => setIsOpen(false)}
            />
        </>
    );
};

export default StoreSourcesNavButton;
