import React from 'react';
import cx from 'classnames';
import { SoundRecordingSearchResultItem } from 'src/apollo/selectors';
import GridTable from 'src/components/gridTable';
import CatalogLoadingIndicator from 'src/components/table/cells/loadingIndicators/catalogLoadingIndicator';
import EntityLoadingIndicator from 'src/components/table/cells/loadingIndicators/entityLoadingIndicator';
import { TABLE_RESULTS_COMMON_CLASSNAME } from 'src/pages/searchES/constants';
import { TableCommonProps } from 'src/pages/searchES/pages/table';

export const CLASSNAME = 'SongResultsGridTable-allResults';
export const TABLE_CONTAINER_MIN_HEIGHT_CLASSNAME = `${CLASSNAME}--tableContainerMinHeight`;
export const TEST_ID = CLASSNAME;
export const DATA_MINIMUM_LENGTH = 3;

export interface Props extends Omit<TableCommonProps, 'term'> {
    data?: SoundRecordingSearchResultItem[];
}

const defaultColumnDefs = {
    sortable: true,
};

const SongResultsGridTable: React.FC<Props> = ({ loading, data }) => {
    const handledData = loading ? [] : data;
    const songsCount = data?.length || 0;
    const isDataMinimumLength = loading
        ? false
        : songsCount <= DATA_MINIMUM_LENGTH;

    return (
        <GridTable
            name={CLASSNAME}
            className={cx(CLASSNAME, TABLE_RESULTS_COMMON_CLASSNAME, {
                [TABLE_CONTAINER_MIN_HEIGHT_CLASSNAME]: isDataMinimumLength,
            })}
            testId={TEST_ID}
            data={handledData}
            loading={loading}
            defaultColumnDefs={defaultColumnDefs}
            rowKey="id"
            hideColumnHeaders
            loadingRows={7}
            bordered
            customizable={false}
            exportable={false}
        >
            <GridTable.Column
                name="inCatalog"
                template="inCatalog"
                minWidth={'36px'}
                LoadingIndicator={CatalogLoadingIndicator}
            />
            <GridTable.Column
                name="name"
                template="thumbnailSongLinkWithIsrcAndArtist"
                minWidth="319px"
                maxWidth="4fr"
                sortable={false}
                LoadingIndicator={() => (
                    <EntityLoadingIndicator
                        withMetaData={true}
                        withLabel={false}
                    />
                )}
            />
        </GridTable>
    );
};

export default SongResultsGridTable;
