import React, { FC, ReactElement } from 'react';
import {
    GridTableProps,
    GridTableSortBy,
    Section,
    GridTable as SuiteGridTable,
} from '@theorchard/suite-components';
import { formatMessage, useFeatureFlag } from '@theorchard/suite-frontend';
import { OutlineIcon } from '@theorchard/suite-icons';
import cx from 'classnames';
import { SortDirection } from 'src/components/table/types';
import {
    INSIGHTS_GRID_TABLE_ZEBRA_STYLE,
    INSIGHTS_SOURCES_TABLE_POPUP_V2,
} from 'src/constants/featuresFlags';
import { PAGE_OVERVIEW } from 'src/constants/page';
import { SOURCE_STREAMS, Source, SourceType } from 'src/constants/stores';
import { useRouteParams } from 'src/utils/route';
import {
    ENGAGE_CUSTOMIZE_COLUMNS,
    TABLE_SORTED,
    trackEngagementEvent,
} from 'src/utils/segment';
import SourceModalContent from './components/sources';
import GridTableSummaryRow from './components/summaryRow';
import './extensions';
import { ColumnConfig } from './types';

export const CLASSNAME = 'InsightsGridTable';
export const CLASSNAME_SECTION_TABLE = `${CLASSNAME}-section-table`;

export interface Props<T>
    extends Omit<GridTableProps<T>, 'onSort' | 'onExport' | 'name'> {
    name: string;
    columnNameToSortNameMap?: Record<string, string>;
    sortBy?: GridTableSortBy | GridTableSortBy[];
    sources?: Source[];
    sourcesTypes?: SourceType[];
    onExport?: (visibleColumns: string[]) => Promise<void> | void;
    onSort?: (name: string, direction: SortDirection) => void;
    onViewSources?: () => void;
}

const ZebraGridTableWrapper: FC = ({ children }) => (
    <Section>
        <Section.Body>
            <Section.Table className={CLASSNAME_SECTION_TABLE}>
                {children}
            </Section.Table>
        </Section.Body>
    </Section>
);

const VoidGridTableWrapper: FC = ({ children }) => <>{children}</>;

const GridTable = <T,>({
    className,
    sortBy = [],
    loadingRows = 20,
    emptyStateTitle = formatMessage('noData.available'),
    columnNameToSortNameMap,
    onSort,
    onExport,
    children,
    variant,
    sources = [],
    sourcesTypes = [SOURCE_STREAMS],
    onViewSources,
    ...rest
}: Props<T>): ReactElement<Props<T>> => {
    const isSourcesV2Enabled = useFeatureFlag(INSIGHTS_SOURCES_TABLE_POPUP_V2);
    const isZebraStyleEnabled = useFeatureFlag(INSIGHTS_GRID_TABLE_ZEBRA_STYLE);
    const [params] = useRouteParams();
    const sortByArray = Array.isArray(sortBy) ? sortBy : [sortBy];
    const sortByMapped: GridTableSortBy[] = columnNameToSortNameMap
        ? sortByArray.map(({ key: sortByKey, ...restSortProps }) => ({
              ...restSortProps,
              key:
                  Object.keys(columnNameToSortNameMap).find(
                      columnName =>
                          columnNameToSortNameMap[columnName] === sortByKey
                  ) || sortByKey,
          }))
        : sortByArray;

    const handleSort = onSort
        ? ([{ key, direction }]: GridTableSortBy[]) => {
              const mappedKey = columnNameToSortNameMap?.[key] || key;

              onSort(mappedKey, direction);
              trackEngagementEvent(TABLE_SORTED, {
                  tab: params.page ?? PAGE_OVERVIEW,
                  column: mappedKey,
                  'sort-direction': direction,
              });
          }
        : undefined;

    const handleExportCsv = onExport
        ? async (columns: ColumnConfig[]) => {
              const visibleColumns = columns
                  .filter(
                      ({ visibility }) =>
                          visibility === 'visible' ||
                          visibility === 'visible-locked'
                  )
                  .map(({ name }) => name);
              await onExport(visibleColumns);
          }
        : undefined;

    const handleCustomizeSaved = (
        columns: { name: string; visibility: string }[]
    ) => {
        const trackableColumns = columns.reduce(
            (acc, { name, visibility }) => ({ ...acc, [name]: visibility }),
            {}
        );

        trackEngagementEvent(ENGAGE_CUSTOMIZE_COLUMNS, {
            tab: params.page ?? PAGE_OVERVIEW,
            ...trackableColumns,
        });
    };

    const showSource = sources.length > 0 && !isSourcesV2Enabled;

    const isZebraStyleActive = isZebraStyleEnabled || variant === 'zebra';
    const Wrapper = isZebraStyleActive
        ? ZebraGridTableWrapper
        : VoidGridTableWrapper;

    return (
        <Wrapper>
            <SuiteGridTable
                testId={CLASSNAME}
                bordered
                customizable
                exportable
                loadingRows={loadingRows}
                emptyStateTitle={emptyStateTitle}
                emptyStateIcon={() => (
                    <OutlineIcon name="AnalyticsOutlineIcon" />
                )}
                showSource={showSource}
                SourceComponent={
                    <SourceModalContent
                        sources={sources}
                        sourcesTypes={sourcesTypes}
                    />
                }
                {...rest}
                className={cx(CLASSNAME, className)}
                onExport={handleExportCsv}
                onSort={handleSort}
                sortBy={sortByMapped}
                onCustomizeSaved={handleCustomizeSaved}
                variant={isZebraStyleActive ? 'zebra' : undefined}
            >
                {children}
            </SuiteGridTable>
        </Wrapper>
    );
};

GridTable.SummaryRow = GridTableSummaryRow;
GridTable.Column = SuiteGridTable.Column;

export default GridTable;
