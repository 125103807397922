import React, { FC } from 'react';
import { InfoMessage } from '@theorchard/suite-components';
import { formatMessage } from '@theorchard/suite-frontend';
import { SharedErrorV2Props } from 'src/components/errorV2/types';

const NoResults: FC<SharedErrorV2Props> = ({ mode, title, message }) => {
    if (mode === 'tinySection') {
        return (
            <>
                {message ??
                    formatMessage('error.noResultsFilteringAdjust.message')}
            </>
        );
    }

    return (
        <InfoMessage
            illustration="noResults"
            message={title ?? formatMessage('error.noResults.title')}
            body={
                message ??
                formatMessage('error.noResultsFilteringAdjust.message')
            }
            size={mode === 'section' ? 'sm' : 'lg'}
        />
    );
};

export default NoResults;
