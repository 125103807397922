import { formatDate, formatMessage } from '@theorchard/suite-frontend';
import dayjs from 'dayjs';
import first from 'lodash/first';
import last from 'lodash/last';
import { UpdateStatus } from 'src/apollo/definitions/globalTypes';
import { SOURCE_DATE_FORMAT } from 'src/constants';

const getMissingDatesRanges = (missingDates: string[]) => {
    return missingDates.reduce(
        (dateRanges: string[][], currentDate, dateIndex) => {
            // If the current iterable date is already included in one of date ranges, skip it
            if (dateRanges.some(date => date.includes(currentDate)))
                return dateRanges;

            // Add date and all adjacent dates next to it
            const adjacentDates: string[] = [];

            missingDates.slice(dateIndex).forEach((date, index) => {
                const isDateAdjacent =
                    dayjs(date).diff(dayjs(currentDate), 'day') === index;
                if (isDateAdjacent) {
                    adjacentDates.push(date);
                }
            });

            return [...dateRanges, adjacentDates];
        },
        []
    );
};

export const getSourceTerm = (
    updateStatus?: UpdateStatus,
    hasMissingDates?: boolean
) => {
    if (hasMissingDates) return formatMessage('sources.missingDates');
    if (updateStatus === UpdateStatus.WAITING_FOR_UPDATES)
        return formatMessage('sources.waitingUpdates');
    if (updateStatus === UpdateStatus.UPDATED)
        return formatMessage('sources.updated');
    return '';
};

export const getMissingDatesTooltip = (missingDates: string[]) => {
    if (missingDates?.length === 1)
        return formatDate(missingDates[0], SOURCE_DATE_FORMAT);

    const ranges = getMissingDatesRanges(missingDates);
    const tooltipText = ranges
        .map(dates => {
            const firstDate = formatDate(first(dates), SOURCE_DATE_FORMAT);
            const lastDate =
                dates.length > 1
                    ? formatDate(last(dates), SOURCE_DATE_FORMAT)
                    : '';
            return `${firstDate}${dates.length > 1 ? ' - ' : ''}${lastDate}`;
        })
        .join(', ');

    return tooltipText;
};

export const getVariant = (
    updateStatus?: UpdateStatus,
    hasMissingDates?: boolean
) => {
    if (hasMissingDates || updateStatus === UpdateStatus.WAITING_FOR_UPDATES)
        return 'warning';
    if (updateStatus === UpdateStatus.UPDATED) return 'success';
    return 'neutral';
};
