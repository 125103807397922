import React, { FC } from 'react';
import { HelpTooltip } from '@theorchard/suite-components';
import { formatMessage } from '@theorchard/suite-frontend';
import FeatureFlagPanel from 'src/components/featureFlagPanel';
import PlatformIcon from 'src/components/platformIcon';
import { INSIGHTS_GRID_TABLE_ZEBRA_STYLE } from 'src/constants/featuresFlags';
import { STORE_TIKTOK } from 'src/constants/stores';
import { GridTableColumnTextProps } from './types';

const TerndScoreHeaderCell: FC<GridTableColumnTextProps> = ({ title }) => (
    <FeatureFlagPanel
        featureFlag={INSIGHTS_GRID_TABLE_ZEBRA_STYLE}
        fallback={
            <div className="TiktokHeaderCell">
                <PlatformIcon
                    className="TiktokHeaderCell-icon"
                    platform={STORE_TIKTOK}
                />
                <div className="TiktokHeaderCell-title">
                    {title}
                    <HelpTooltip
                        id="trendScore-tooltip"
                        message={formatMessage(
                            'catalog.tikTokTrendScoreTooltip'
                        )}
                        tooltipPlacement="right"
                    />
                </div>
            </div>
        }
    >
        <div className="TiktokHeaderCell-zebra">
            <HelpTooltip
                id="trendScore-tooltip"
                message={formatMessage('catalog.tikTokTrendScoreTooltip')}
                tooltipPlacement="top"
            />

            <div className="TiktokHeaderCell-zebra-title">
                <PlatformIcon
                    className="TiktokHeaderCell-zebra-icon"
                    platform={STORE_TIKTOK}
                    size="12"
                />
                {title}
            </div>
        </div>
    </FeatureFlagPanel>
);

export default TerndScoreHeaderCell;
