import React, { VFC } from 'react';
import PlatformIcon from 'src/components/platformIcon';
import { STORE_TIKTOK } from 'src/constants/stores';
import { GridTableColumnTextProps } from './types';

const TiktokHeaderCell: VFC<GridTableColumnTextProps> = ({ title }) => (
    <div className="TiktokHeaderCell">
        <PlatformIcon
            className="TiktokHeaderCell-icon"
            platform={STORE_TIKTOK}
            size="12"
        />
        <div className="TiktokHeaderCell-title">{title}</div>
    </div>
);

export default TiktokHeaderCell;
