import React from 'react';

const StoreStatusNavIcon = () => (
    <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="Icon NavIcon StoreStatusNavIcon"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M 14.980469 4.039062 C 15.328125 4.011719 15.660156 4 16.007812 4 C 22.632812 4 28.007812 9.375 28.007812 16 C 28.007812 22.625 22.632812 28 16.007812 28 C 15.660156 28 15.3125 27.988281 14.980469 27.960938 C 14.246094 27.894531 13.765625 27.1875 13.914062 26.464844 C 14.019531 25.921875 14.433594 25.535156 14.941406 25.386719 C 15.332031 25.332031 15.632812 25.332031 15.992188 25.332031 C 21.152344 25.332031 25.328125 21.160156 25.328125 16 C 25.328125 10.839844 21.152344 6.667969 15.992188 6.667969 C 15.640625 6.667969 15.332031 6.667969 14.941406 6.613281 C 14.414062 6.542969 14.019531 6.066406 13.914062 5.535156 C 13.78125 4.8125 14.246094 4.105469 14.980469 4.039062 Z M 14.980469 4.039062 "
            fill="white"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M 9.765625 9.054688 C 9.527344 9.28125 9.285156 9.507812 9.058594 9.761719 C 8.578125 10.304688 7.753906 10.464844 7.140625 10.066406 C 6.527344 9.667969 6.351562 8.828125 6.832031 8.265625 C 7.273438 7.746094 7.753906 7.265625 8.273438 6.828125 C 8.832031 6.347656 9.660156 6.535156 10.074219 7.132812 C 10.488281 7.734375 10.3125 8.558594 9.765625 9.054688 Z M 9.765625 9.054688 "
            fill="white"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M 9.058594 22.238281 C 8.566406 21.691406 7.738281 21.519531 7.140625 21.933594 C 6.527344 22.347656 6.351562 23.171875 6.832031 23.734375 C 7.273438 24.253906 7.753906 24.734375 8.273438 25.171875 C 8.832031 25.652344 9.671875 25.480469 10.074219 24.867188 C 10.488281 24.253906 10.3125 23.425781 9.765625 22.945312 C 9.511719 22.71875 9.285156 22.480469 9.058594 22.238281 Z M 9.058594 22.238281 "
            fill="white"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M 6.671875 15.492188 C 6.714844 14.761719 6.246094 14.054688 5.527344 13.90625 C 4.808594 13.761719 4.101562 14.238281 4.035156 14.972656 C 4.007812 15.308594 3.992188 15.652344 3.992188 16 C 3.992188 16.347656 4.007812 16.679688 4.035156 17.027344 C 4.101562 17.761719 4.808594 18.226562 5.527344 18.09375 C 6.246094 17.945312 6.714844 17.253906 6.671875 16.507812 Z M 6.671875 15.492188 "
            fill="white"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M 20.878906 15.003906 C 21.433594 14.519531 21.488281 13.675781 21.003906 13.121094 C 20.519531 12.566406 19.675781 12.511719 19.121094 12.996094 L 14.726562 16.84375 L 12.941406 15.058594 C 12.421875 14.535156 11.578125 14.535156 11.058594 15.058594 C 10.535156 15.578125 10.535156 16.421875 11.058594 16.941406 L 13.722656 19.609375 C 14.21875 20.105469 15.015625 20.132812 15.542969 19.671875 Z M 20.878906 15.003906 "
            fill="white"
        />
    </svg>
);

export default StoreStatusNavIcon;
