import React from 'react';
import { Status } from '@theorchard/suite-components';
import { formatMessage } from '@theorchard/suite-frontend';
import { useAnalyticsFeedsStatus } from 'src/apollo/queries';
import Error from 'src/components/errorV2';
import GridTable from 'src/components/gridTable';
import { Source } from 'src/constants/stores';
import { getSourceTerm, getVariant } from './utils';

const CLASSNAME = 'StoreStatusGridTable';

const StoreStatusGridTable: React.FC = () => {
    const { data, loading } = useAnalyticsFeedsStatus();

    return (
        <GridTable
            name={CLASSNAME}
            testId={CLASSNAME}
            data={data}
            loading={loading}
            bordered
            rowKey="storeId"
            customizable={false}
            exportable={false}
            defaultColumnDefs={{
                sortable: false,
                align: 'left',
            }}
            emptyStateComponent={<Error mode="section" type="noData" />}
            stickyHeader
            maxHeight="70vh"
        >
            <GridTable.Column
                name="storeId"
                title={formatMessage('chart.store')}
                template="platform"
            />
            <GridTable.Column<Source>
                name="status"
                title={formatMessage('sources.types.status')}
                Cell={({ data: { updateStatus } }) => (
                    <Status
                        filled
                        text={getSourceTerm(updateStatus)}
                        variant={getVariant(updateStatus)}
                    />
                )}
            />
            <GridTable.Column
                name="latestDate"
                align="left"
                title={formatMessage('sources.lastAvailableDate')}
                template="date"
            />
        </GridTable>
    );
};

export default StoreStatusGridTable;
