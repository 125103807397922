import React from 'react';
import { GridTable, Tooltip } from '@theorchard/suite-components';
import { uniqueId } from 'lodash';
import LinkList from 'src/components/linkList';
import { EMPTY_CHAR, ROUTE_PARTICIPANT } from 'src/constants';

GridTable.defineColumnTemplate('participantLink', {
    align: 'left',
    sortable: false,
    Cell: ({ data: { participants } }) => {
        const message = participants?.map(({ name }) => name).join(', ');
        if (!message) return <span>{EMPTY_CHAR}</span>;

        return (
            <Tooltip
                id={`participantLink_${uniqueId()}`}
                message={message}
                className="ParticipantLinkCell"
            >
                <LinkList
                    links={participants}
                    route={ROUTE_PARTICIPANT}
                    separator="comma"
                />
            </Tooltip>
        );
    },
});
