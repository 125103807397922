import React from 'react';
import { GridTable } from '@theorchard/suite-components';
import { formatMessage } from '@theorchard/suite-frontend';
import CoverArt from 'src/components/coverArt';
import TruncatedText from 'src/components/truncatedText';
import { ROUTE_SONG, ROUTE_VIDEO } from 'src/constants';
import {
    RelatedEntityTypes,
    VIDEO_TERM,
} from 'src/pages/video/components/relatedContent/constants';
import { getVideoType } from 'src/utils/videos';

const CLASSNAME = 'RelatedEntityCell';
const CLASSNAME_METADATA = `${CLASSNAME}-metadata`;
const CLASSNAME_TYPE = `${CLASSNAME}-type`;

GridTable.defineColumnTemplate('relatedEntity', {
    align: 'left',
    sortable: false,
    minWidth: '0px',
    Cell: ({ data }) => {
        const isSong = data.type === RelatedEntityTypes.SoundRecording;
        const entityType = isSong
            ? formatMessage('catalog.songName')
            : `${formatMessage(VIDEO_TERM)} - ${getVideoType(
                  data.channelOwner,
                  data.videoContentType
              )}`;

        return (
            <div className={CLASSNAME} data-testid={CLASSNAME}>
                <CoverArt
                    url={data.entity.imageUrl}
                    shape="letterbox"
                    width="53"
                />
                <div className={CLASSNAME_METADATA}>
                    <TruncatedText
                        useLink
                        route={isSong ? ROUTE_SONG : ROUTE_VIDEO}
                        params={
                            isSong
                                ? { isrc: data.entity.id }
                                : { id: data.entity.id }
                        }
                        value={data.entity.name}
                        maxLength={50}
                    />
                    <span className={CLASSNAME_TYPE}>{entityType}</span>
                </div>
            </div>
        );
    },
});
