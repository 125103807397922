import { formatMessage } from '@theorchard/suite-frontend';

export const APP_NAME = 'frontend-insights';

export const ENV_PROD = 'prod';
export const ENV_QA = 'qa';

export const ROUTE_HOME = '/';

export const ROUTE_CHARTS = '/charts';
export const ROUTE_GAINERS = '/gainers';
export const ROUTE_TRENDING = '/trending/:page?';
export const ROUTE_CHART = '/chart/:id';
export const ROUTE_CHART_V2 = '/chart';
export const ROUTE_SONG = '/song/:isrc/:page?';
export const ROUTE_COMPARE_SONG = '/compare/song/:isrc/:page?';
export const ROUTE_PARTICIPANT = '/artist/:id/:page?';
export const ROUTE_COMPARE_PARTICIPANT = '/compare/artist/:id/:page?';
export const ROUTE_SUBACCOUNT =
    '/account/:accountId/subaccount/:subaccountId/:page?';
export const ROUTE_ACCOUNT = '/account/:accountId/:page?';

export const ROUTE_SEARCH = '/search/:page?';
export const ROUTE_CATALOG_STRICT = '/catalog';
export const ROUTE_CATALOG = '/catalog/:page?';
export const ROUTE_CHANNEL = '/channel/:id/:page?';
export const ROUTE_VIDEO = '/video/:id/:page?';
export const ROUTE_FAVORITES = '/favorites/:page?';
export const ROUTE_PRODUCT = '/product/:upc/:page?';
export const ROUTE_SETTINGS = '/settings';
export const ROUTE_PREFERENCES = '/preferences';

export const ROUTE_NEW_RELEASES = '/newReleases';

export const ROUTES = {
    charts: ROUTE_CHARTS,
    chart: ROUTE_CHART,
    song: ROUTE_SONG,
    compareSong: ROUTE_COMPARE_SONG,
    artist: ROUTE_PARTICIPANT,
    compareArtist: ROUTE_COMPARE_PARTICIPANT,
    subaccount: ROUTE_SUBACCOUNT,
    account: ROUTE_ACCOUNT,
    search: ROUTE_SEARCH,
    catalog: ROUTE_CATALOG,
    channel: ROUTE_CHANNEL,
    video: ROUTE_VIDEO,
    favorites: ROUTE_FAVORITES,
    product: ROUTE_PRODUCT,
    settings: ROUTE_SETTINGS,
    gainers: ROUTE_GAINERS,
    newReleases: ROUTE_NEW_RELEASES,
};

export const PROFILE_INSIGHTS = 'InsightsProfile';

export const DEFAULT_LOCALE = 'en';

export const EMPTY_CHAR = '-';
export const DASH_CHAR = '–';
export const SONG_DATE_FORMAT = 'DD MMM YYYY';
export const DATE_FORMAT_FULL = 'DD MMMM YYYY';
export const SONG_CARD_DATE_FORMAT = 'MMM DD YYYY';
export const DATE_FORMAT = 'YYYY-MM-DD';
export const SOURCE_DATE_FORMAT = 'D MMM YYYY';

export const DATE_FORMAT_LONG = 'ddd, D MMMM YYYY';
export const DATETIME_FORMAT_LONG = 'ddd, D MMMM YYYY HH:mm (Z)';
export const FULL_DATE_FORMAT = 'ddd, MMM D, YYYY';
export const FULL_WEEK_DATE_FORMAT = 'dddd, MMM D, YYYY';
export const DATE_WITH_YEAR_FORMAT = 'MMM D, YYYY';
export const UNNAMED = '--no-name--';
export const SORT_LAST = 'zzzzzz';

export const ERROR_UNAUTHENTICATED = 'UnauthenticatedError';
export const ERROR_NETWORK = 'NetworkError';

export const ORCHARD_SUITE_SVG_ICONS = 'orchard_suite_svg_icons';

export const TERM_SHOW_X_RESULTS = 'search.showXResults';

export const DISTRIBUTORS_AWAL = 'awal';
export const DISTRIBUTORS_ORCH_SME_AWAL = 'theorchard,sme,awal';
export const DISTRIBUTORS_ORCH_SME = 'theorchard,sme';
export const DISTRIBUTORS_ORCH = 'theorchard';
export const DISTRIBUTORS_ORCH_AWAL = 'theorchard,awal';
export const DISTRIBUTORS_SME = 'sme';
export const DISTRIBUTORS_SME_AFFILIATES = 'sme-affiliates';
export const DISTRIBUTORS_HRS = 'hrs';
export const BRAND_ORCH = 'theorchard';
export const BRAND_SME = 'sme';
export const BRAND_SME_AFFILIATES = 'sme-affiliates';
export const BRAND_SME_GERMANY = 'sme-germany';
export const BRAND_COLUMBIA = 'columbia';
export const BRAND_HRS = 'hrs';
export const BRAND_AWAL = 'awal';
export const BRAND_AB = 'ab';
export const BRAND_MSK = 'msk';
export const BRAND_KNR = 'knr';
export const BRAND_MASS_APPEAL = 'ma';
export const BRAND_DRM = 'drm';
export const BRAND_ALTAFONTE = 'altafonte';

export const DISTRIBUTOR_OPTIONS = [
    {
        label: formatMessage('distributors.all'),
        value: DISTRIBUTORS_ORCH_AWAL,
    },
    {
        label: formatMessage('distributors.orchard'),
        value: DISTRIBUTORS_ORCH,
    },
    {
        label: formatMessage('distributors.awal'),
        value: DISTRIBUTORS_AWAL,
    },
];

export const DISTRIBUTOR_OPTIONS_WITH_AWAL_AND_SME = [
    {
        label: formatMessage('distributors.all'),
        value: DISTRIBUTORS_ORCH_SME_AWAL,
    },
    {
        label: formatMessage('distributors.orchard'),
        value: DISTRIBUTORS_ORCH,
    },
    {
        label: formatMessage('distributors.sme'),
        value: DISTRIBUTORS_SME,
    },
    {
        label: formatMessage('distributors.sme_affiliates'),
        value: DISTRIBUTORS_SME_AFFILIATES,
    },
    {
        label: formatMessage('distributors.awal'),
        value: DISTRIBUTORS_AWAL,
    },
    {
        label: formatMessage('distributors.hrs'),
        value: DISTRIBUTORS_HRS,
    },
];

export const MAIN_CONTENT_CLASSNAME = 'MainContent';
export const QUERY_VALUE_TRUE = 'true';
export const QUERY_VALUE_FALSE = 'false';

export const VH_RECALCULATE_RATE = 50;

export const FILTER_DEBOUNCE = 200;
export const ARTISTS_FILTER_LIMIT = 10;
export const PLAYLIST_GENRES_LIMIT = 3;

export const TERRITORY_RIGHTS = {
    NONE: 'none',
    FULL: 'full',
    SPLIT: 'split',
};

export const YOUTUBE_CLAIM_TYPES = {
    AUDIO: 'audio',
    VISUAL: 'visual',
    AUDIOVISUAL: 'audiovisual',
};

export const YOUTUBE_CLAIM_STATUSES = [
    'active',
    'potential',
    'disputed',
    'appealed',
    'pending',
    'takendown',
    'delayed_takedown',
    'takedown_under_review',
];

export const PARAM_COUNTRY_CODE = 'country';
export const PARAM_BRAND = 'companyBrand';
export const PARAM_PARENT_BRAND = 'parentCompany';

export const GRID_TABLE_CLASSNAME = 'GridTable-table';
export const ERROR_TYPES = {
    ERROR: 'error',
    INTERNAL_SERVER_ERROR: 'internalServerError',
    NO_RESULTS: 'noResults',
    NO_RESULTS_IN_CATALOG: 'noResultsInCatalog',
    NO_DATA: 'noData',
};

// TODO: update this once designers have decision regarding fallback image
export const fallbackImage =
    'https://images.theorchard.io/product/cover/2537c362d86a9b84f05f1b360aa11ff1.jpg';

export const TOGGLE_ON_STATE = 'on';
export const TOGGLE_OFF_STATE = 'off';

export const OTHER = 'other';
export const ALL = 'all';
