import { TypePolicies } from '@apollo/client';
import withPolicyExpiration from 'src/apollo/utils/withPolicyExpiration';
import appRecents from './appRecents';

const typePolicies: TypePolicies = {
    Identity: {
        keyFields: ['id'],
    },
    AnalyticsFeed: {
        keyFields: ['id'],
    },
    ProfileResource: {
        keyFields: ['id'],
    },
    PublicProduct: {
        keyFields: ['id'],
        merge: true,
    },
    GlobalProduct: {
        keyFields: ['upc'],
        merge: true,
    },
    Product: {
        keyFields: ['upc'],
        merge: true,
    },
    ServiceTier: {
        keyFields: ['name'],
    },
    CompanyBrand: {
        keyFields: ['name'],
    },
    ParentCompany: {
        keyFields: ['name'],
    },
    ChartV2: {
        keyFields: ['id'],
        merge: true,
    },
    GlobalSoundRecording: {
        keyFields: ['isrc'],
        fields: {
            analytics: {
                merge: true
            },
            tiktok: {
                merge: true,
            },
        }
    },
    GlobalParticipant: {
        keyFields: ['id'],
        fields: {
            analytics: {
                merge: true
            },
            labelParticipants: {
                merge: true,
            },
        }
    },
    PublicParticipant: {
        keyFields: ['id'],
        fields: {
            socialStatsV2: {
                merge: true
            }
        }
    },
    SoundRecording: {
        keyFields: ['isrc'],
        fields: {
            analytics: {
                merge: true
            }
        }
    },
    PublicSoundRecording: {
        keyFields: ['id'],
        fields: {
            chartAggregatedRankings: {
                merge: false
            }
        }
    },
    Video: {
        keyFields: ['videoId'],
        fields: {
            analytics: {
                merge: true
            }
        }
    },
    Channel: {
        keyFields: ['channelId'],
        fields: {
            analytics: {
                merge: true
            }
        }
    },
    Subaccount: {
        keyFields(entry) {
            if (entry?.id && typeof entry.id === 'object')
                return ['id', ['vendorId', 'subaccountId']];

            return false;
        },
        merge: true,
        fields: {
            analytics: {
                merge: true,
            },
            topContent: {
                merge: true,
            },
        },
    },
    Vendor: {
        keyFields(entry) {
            if (entry?.id && typeof entry.id === 'object')
                return ['id', ['vendorId']];

            return false;
        },
        merge: true,
        fields: {
            analytics: {
                merge: true,
            },
            topContent: {
                merge: true,
            },
        },
    },
    GlobalParticipantAnalyticsSummaryProductItem: {
        keyFields: false,
    },
    GlobalParticipantAnalyticsSummaryGenericItem: {
        keyFields: false,
    },
    GlobalParticipantAnalyticsSummaryTrackItem: {
        keyFields: false,
    },
    GlobalParticipantAnalyticsTimeseriesItem: {
        keyFields: false,
    },
    GlobalSoundRecordingSummaryStreamSourceItem: {
        keyFields: false,
    },
    GlobalSoundRecordingTimeseriesItem: {
        keyFields: false,
    },
    GlobalSoundRecordingSummaryTotalItem: {
        keyFields: false,
    },
    GlobalSoundRecordingSummaryStoreItem: {
        keyFields: false,
    },
    GlobalSoundRecordingSummaryProductItem: {
        keyFields: false,
    },
    GlobalSoundRecordingSummaryCountryItem: {
        keyFields: false,
    },
    AccountSummaryGenericItem: {
        keyFields: false,
    },
    AccountTimeseriesItem: {
        keyFields: false,
    },
    TiktokSummaryItem: {
        keyFields: false,
    },
    TiktokTimeSeriesItem: {
        keyFields: false,
    },
    ProductSummaryGenericItem: {
        keyFields: false,
    },
    ProductSummaryTrackItem: {
        keyFields: false,
    },
    ProductTimeseriesItem: {
        keyFields: false,
    },
    PlaylistPlacementsBreakdownItem: {
        keyFields: false,
    },
    Query: {
        fields: {
            appRecents: withPolicyExpiration(appRecents, { value: 8, unit: 'day' }, 'appRecents'),
            activeProfile: {
                merge: true,
            },
            orchardLabel: {
                keyArgs: ['id', ['vendorId', 'subaccountId']],
                merge: true,
            },
        }
    }
};

export default typePolicies;
