import React, { FC } from 'react';
import { Card } from '@theorchard/suite-components';
import cx from 'classnames';
import { PAGE_CONFIG } from 'src/components/errorV2/constants';
import { ErrorMode, ErrorType } from 'src/components/errorV2/types';

interface Props {
    mode: ErrorMode;
    type: ErrorType;
    isCardLayout?: boolean;
    actionButtonHandler?: VoidFunction;
    actionButtonText?: string;
    className?: string;
    title?: string;
    message?: string;
}

export const CLASSNAME = 'ErrorV2';
export const ERROR_TEST_ID = CLASSNAME;

const Error: FC<Props> = ({
    mode,
    type,
    className,
    isCardLayout = false,
    ...props
}) => {
    const ComponentType = PAGE_CONFIG[type];
    const Component = ComponentType && <ComponentType mode={mode} {...props} />;
    const Content = isCardLayout ? (
        <Card className={cx({ [mode]: mode })}>
            <Card.Body>{Component}</Card.Body>
        </Card>
    ) : (
        Component
    );

    return (
        <div
            className={cx(
                CLASSNAME,
                {
                    [mode]: !isCardLayout && mode,
                },
                className
            )}
            data-testid={ERROR_TEST_ID}
        >
            {Content}
        </div>
    );
};

export default Error;
