import React from 'react';
import { CoverArt, GridTable, Tooltip } from '@theorchard/suite-components';
import { uniqueId } from 'lodash';
import { concatNames } from 'src/apollo/selectors';
import LinkList from 'src/components/linkList';
import TruncatedText from 'src/components/truncatedText';
import { ROUTE_PARTICIPANT, ROUTE_SONG } from 'src/constants';

const CLASSNAME = 'ThumbnailSongLinkWithArtistCell';
const CLASSNAME_LABEL = `${CLASSNAME}-label`;
const CLASSNAME_LINKS = `${CLASSNAME}-links`;

GridTable.defineColumnTemplate('thumbnailSongLinkWithArtist', {
    align: 'left',
    Cell: props => {
        const { data } = props;
        const { songName, name, isrc, imageUrl, participants } = data;
        const areParticipantsAvailable = participants?.length !== 0;

        return (
            <div className={`${CLASSNAME} CoverArtCell`}>
                <CoverArt url={imageUrl} width="40" />
                <div className={CLASSNAME_LABEL}>
                    <TruncatedText
                        value={songName ?? name}
                        useLink={Boolean(isrc)}
                        route={ROUTE_SONG}
                        params={{ isrc }}
                    />
                    {areParticipantsAvailable && (
                        <Tooltip
                            id={`availableParticipants_${uniqueId()}`}
                            message={concatNames(participants)}
                        >
                            <LinkList
                                route={ROUTE_PARTICIPANT}
                                links={participants}
                                separator="comma"
                                className={CLASSNAME_LINKS}
                            />
                        </Tooltip>
                    )}
                </div>
            </div>
        );
    },
});
