import { formatMessage } from '@theorchard/suite-frontend';
import { capitalize, dropRightWhile, get, keyBy, matches } from 'lodash';
import slugify from 'slugify';
import { ALL } from 'src/constants/index';
import {
    STORE_AMAZON,
    STORE_DISPLAY_NAMES_BY_ID,
    STORE_ID_AMAZON_MUSIC,
    STORE_ID_APPLE_MUSIC,
    STORE_ID_DEEZER,
    STORE_ID_LINE,
    STORE_ID_RECOCHOKU,
    STORE_ID_SHAZAM,
    STORE_ID_SOUNDCLOUD,
    STORE_ID_SPOTIFY,
    STORE_ID_TIKTOK,
    STORE_ITUNES,
    STORE_TITLE_AMAZON,
    STORES_BY_ID,
} from 'src/constants/stores';
import { getLocalizedName } from 'src/utils/countries';
import { COUNTRY_GLOBAL } from './countries';

export interface ChartCountryInstance {
    id: string;
    value: string;
    label: string;
    lastAvailableTimestamp?: string;
}

export interface ChartDefinition {
    label: string;
    name: string;
    storeName: string;
    platform: string;
    hasStreamCount: boolean;
    hasTotalStreamCount: boolean;
    hasShazams: boolean;
    linkName?: string;
    timeOnChart?: string;
    type?: string;
    target?: string;
    frequency?: string;
    genre?: string;
    key: string;
    size: number;
    dynamicSize?: boolean;
    match: (id: object) => boolean;
    countries: ChartCountryInstance[];
}

export interface ChartInstance {
    id: string;
    platform?: string;
    type?: string;
    target?: string;
    frequency?: string;
    country?: string;
    genre?: string;
    modifiedAt?: string;
    lastAvailableTimestamp?: string;
    definition: ChartDefinition;
}

interface IChartNameProps {
    genre?: string;
    type?: string;
    platform?: string;
    frequency?: string;
    key: string;
}

export type ChartDictionary = Record<string, ChartInstance>;

export const FREQUENCY_WEEKLY = 'weekly';
export const FREQUENCY_DAILY = 'daily';

export const TYPE_VIRAL = 'viral';
export const TYPE_REGIONAL = 'regional';

const KEY_SEPARATOR = '_';
const ID_KEYS = ['platform', 'target', 'frequency', 'type', 'genre'] as const;
export type ChartIdLike = Partial<Record<(typeof ID_KEYS)[number], string>>;

export const ENTRY_TOOLTIP = 'chart.entryDateTooltip';
export const EXIT_TOOLTIP = 'chart.exitDateTooltip';
export const TIME_ON_CHART_TOOLTIP = 'chart.timeOnChartTooltip';

export const AMAZON_MENU_ITEM_TITLE = 'Amazon Top Tracks';
export const ITUNES_MENU_ITEM_TITLE = 'iTunes Daily Top Albums';

export const MAX_HIGHLIGHTS_COUNT = 10;

export const CHART_GROUP_NAMES: Record<string, string> = {
    'spotify-regional': 'Top 200',
    amazon: 'Top Tracks',
    'tiktok-default': 'Top Tracks',
    'recochoku-single': 'Top 200',
    'linemusic-Top 100': 'Top 100',
    itunes: 'Daily Top Albums',
};

export const createDefinitionKey = (id?: object | null) => {
    const textKey = dropRightWhile(
        ID_KEYS.map(key => get(id, key, '')?.toLowerCase()),
        value => !value
    ).join(KEY_SEPARATOR);

    return slugify(textKey, '_');
};

export const NEW_CHARTS = [{ name: 'example', updated: '2020-08-14' }];

const MAX_NEW_DURATION_DAYS = 30;

export const chartIsRecentlyUpdated = (storeName: string) => {
    // whether we want to show the "New" corner on the chart index
    const newChart = NEW_CHARTS.find(c =>
        storeName.toLowerCase().includes(c.name)
    );
    if (newChart)
        if (
            new Date(newChart.updated).getTime() - new Date().getTime() <
            MAX_NEW_DURATION_DAYS
        )
            return true;
    return false;
};

export const definitions: ChartDefinition[] = [
    {
        storeName: 'Spotify',
        name: 'Daily Viral 100',
        hasStreamCount: false,
        hasShazams: false,
        hasTotalStreamCount: false,
        size: 100,
        id: {
            type: TYPE_VIRAL,
            platform: 'spotify',
            target: 'track',
            frequency: 'daily',
        },
    },
    {
        storeName: 'Spotify',
        name: 'Daily Top 200',
        hasStreamCount: true,
        hasShazams: false,
        hasTotalStreamCount: false,
        size: 200,
        id: {
            type: 'regional',
            platform: 'spotify',
            target: 'track',
            frequency: 'daily',
        },
    },
    {
        storeName: 'Spotify',
        name: 'Weekly Top 200',
        hasStreamCount: true,
        hasShazams: false,
        hasTotalStreamCount: false,
        size: 200,
        id: {
            type: 'regional',
            platform: 'spotify',
            target: 'track',
            frequency: 'weekly',
        },
    },
    {
        storeName: 'Apple Music',
        name: 'Daily Top 100',
        hasStreamCount: false,
        hasShazams: false,
        hasTotalStreamCount: false,
        size: 100,
        id: {
            type: 'default',
            platform: 'appleMusic',
            target: 'track',
            frequency: 'daily',
        },
    },
    {
        storeName: 'Deezer',
        name: 'Daily Top 100',
        hasStreamCount: false,
        hasShazams: false,
        hasTotalStreamCount: false,
        size: 100,
        id: {
            type: 'default',
            platform: 'deezer',
            target: 'track',
            frequency: 'daily',
        },
    },
    {
        storeName: 'TikTok',
        name: 'All Time Top Tracks',
        hasStreamCount: true,
        hasShazams: false,
        hasTotalStreamCount: false,
        linkName: 'allTime',
        size: 1000,
        id: {
            type: 'default',
            platform: 'tiktok',
            target: 'track',
            frequency: 'daily',
        },
    },
    {
        storeName: 'TikTok',
        name: 'Weekly Top Tracks',
        hasStreamCount: true,
        hasShazams: false,
        hasTotalStreamCount: false,
        timeOnChart: 'daily',
        size: 1000,
        id: {
            type: 'default',
            platform: 'tiktok',
            target: 'track',
            frequency: 'weekly',
        },
    },
    {
        storeName: 'SoundCloud',
        name: 'Daily Top Tracks',
        hasStreamCount: false,
        hasShazams: false,
        hasTotalStreamCount: true,
        size: 200,
        id: {
            type: 'top',
            platform: 'soundcloud',
            target: 'track',
            frequency: 'daily',
        },
    },
    {
        storeName: 'SoundCloud',
        name: 'Daily New & Hot',
        hasStreamCount: false,
        hasShazams: false,
        hasTotalStreamCount: true,
        size: 200,
        id: {
            type: 'trending',
            platform: 'soundcloud',
            target: 'track',
            frequency: 'daily',
        },
    },
    {
        storeName: 'Recochoku (Japan)',
        name: 'Daily Top 200',
        hasStreamCount: false,
        hasShazams: false,
        hasTotalStreamCount: false,
        size: 200,
        id: {
            type: 'single',
            platform: 'recochoku',
            target: 'track',
            frequency: 'daily',
        },
    },
    {
        storeName: 'Recochoku (Japan)',
        name: 'Weekly Top 200',
        hasStreamCount: false,
        hasShazams: false,
        hasTotalStreamCount: false,
        size: 200,
        id: {
            type: 'single',
            platform: 'recochoku',
            target: 'track',
            frequency: 'weekly',
        },
    },
    {
        storeName: 'Line Music (Japan)',
        name: 'Daily Top 100',
        hasStreamCount: false,
        hasShazams: false,
        hasTotalStreamCount: false,
        size: 100,
        id: {
            type: 'Top 100',
            platform: 'linemusic',
            target: 'track',
            frequency: 'daily',
        },
    },
    {
        storeName: 'Line Music (Japan)',
        name: 'Weekly Top 100',
        hasStreamCount: false,
        hasShazams: false,
        hasTotalStreamCount: false,
        size: 100,
        id: {
            type: 'Top 100',
            platform: 'linemusic',
            target: 'track',
            frequency: 'weekly',
        },
    },
    {
        storeName: 'Shazam',
        name: 'Daily Top Tracks',
        hasStreamCount: false,
        hasShazams: true,
        hasTotalStreamCount: false,
        size: 200,
        id: {
            type: 'default',
            platform: 'shazam',
            target: 'track',
            frequency: 'daily',
        },
    },
    {
        storeName: 'YouTube',
        name: 'Top Songs',
        hasStreamCount: false,
        hasShazams: false,
        hasTotalStreamCount: false,
        size: 100,
        id: {
            type: 'Tracks',
            platform: 'youtube',
            target: 'track',
            frequency: 'weekly',
        },
    },
    {
        storeName: 'YouTube',
        name: 'Top Videos',
        hasStreamCount: false,
        hasShazams: false,
        hasTotalStreamCount: false,
        size: 100,
        id: {
            type: 'Music Videos',
            platform: 'youtube',
            target: 'video',
            frequency: 'weekly',
        },
    },
].map(
    ({
        storeName,
        name,
        id,
        hasStreamCount,
        hasShazams,
        hasTotalStreamCount,
        linkName,
        timeOnChart,
        size,
    }) =>
        ({
            key: createDefinitionKey(id),
            label: `${storeName} ${name}`,
            name,
            hasStreamCount,
            hasShazams,
            hasTotalStreamCount,
            linkName,
            timeOnChart,
            storeName,
            size,
            countries: [],
            match: matches(id),
            ...id,
        } as ChartDefinition)
);

const EMPTY_MATCHER = () => false;
const definitionsByKey = keyBy(definitions, 'key');
export const startCase = (str?: string) =>
    str ? str.replace(/[^\s]+/g, capitalize) : '';

export const getChartName = ({
    platform,
    type,
    frequency,
    key,
    genre,
}: IChartNameProps) => {
    switch (platform) {
        case STORE_AMAZON:
        case STORE_ITUNES: {
            if (frequency && genre) return startCase(`${frequency} ${genre}`);
            if (frequency && type) return startCase(`${frequency} ${type}`);

            return key;
        }
        default: {
            if (frequency && type) return startCase(`${frequency} ${type}`);
            if (frequency && genre) return startCase(`${frequency} ${genre}`);

            return key;
        }
    }
};

const isDynamicSize = (platform?: string) => {
    if (platform === 'amazon') return true;
    return false;
};

export const createDefinition = (id: Partial<ChartInstance>) => {
    const key = createDefinitionKey(id);
    const { target, frequency, type, platform, genre } = id;

    const storeName =
        platform !== 'itunes' ? startCase(platform || '') : 'iTunes';
    const name = getChartName({ platform, type, frequency, key, genre });

    return {
        target,
        frequency,
        type,
        platform,
        genre,
        key,
        label: `${storeName} ${name}`,
        name,
        storeName,
        size: 0,
        dynamicSize: isDynamicSize(platform),
        hasStreamCount: false,
        hasShazams: false,
        hasTotalStreamCount: false,
        match: EMPTY_MATCHER,
        countries: [
            {
                id: '',
                value: COUNTRY_GLOBAL,
                label: getLocalizedName(COUNTRY_GLOBAL),
            },
        ],
    } as ChartDefinition;
};

export const getDefinition = (id?: object | null) => {
    if (!id) return createDefinition({});
    return definitions.find(({ match }) => match(id)) || createDefinition(id);
};

export const getDefinitionKey = (id: object) =>
    getDefinition(id)?.key || createDefinitionKey(id);
export const getDefinitionByKey = (key: string) =>
    definitionsByKey[key] as ChartDefinition | undefined;

export const DEFAULT_SERIES_CLASSNAME = 'chart-series-default';

export const ORDERED_TABS_STORES = [
    STORE_ID_SPOTIFY,
    STORE_ID_APPLE_MUSIC,
    STORE_ID_SHAZAM,
    STORE_ID_DEEZER,
    STORE_ID_TIKTOK,
    STORE_ID_AMAZON_MUSIC,
    STORE_ID_SOUNDCLOUD,
    STORE_ID_RECOCHOKU,
    STORE_ID_LINE,
];
export const RESOLVED_STORE_TITLES: Record<string, string> = {
    ...STORE_DISPLAY_NAMES_BY_ID,
    [STORE_ID_LINE]: 'Line Music',
    [STORE_ID_AMAZON_MUSIC]: STORE_TITLE_AMAZON,
    [ALL]: 'All',
};

export const RESOLVED_STORES_BY_ID: Record<string, string> = {
    ...STORES_BY_ID,
    [STORE_ID_AMAZON_MUSIC]: STORE_AMAZON,
};
export const DAILY_TEXT = formatMessage('chart.daily');
export const WEEKLY_TEXT = formatMessage('chart.weekly');
export const VIRAL_TEXT = formatMessage('chart.viral');
export const ALL_TIME_TEXT = formatMessage('chart.allTime');
export const SHARED_CHART_TYPES_TOGGLE_GROUP_ITEMS = [
    { label: DAILY_TEXT, value: FREQUENCY_DAILY },
    { label: WEEKLY_TEXT, value: FREQUENCY_WEEKLY },
];
export const SPOTIFY_CHART_TYPES_TOGGLE_GROUP_ITEMS = [
    { label: `${DAILY_TEXT} 200`, value: FREQUENCY_DAILY },
    { label: `${WEEKLY_TEXT} 200`, value: FREQUENCY_WEEKLY },
    { label: `${DAILY_TEXT} ${VIRAL_TEXT} 100`, value: TYPE_VIRAL },
];
export const TIKTOK_CHART_TYPES_TOGGLE_GROUP_ITEMS = [
    { label: ALL_TIME_TEXT, value: FREQUENCY_DAILY },
    { label: WEEKLY_TEXT, value: FREQUENCY_WEEKLY },
];

export interface ChartTypesToggleGroupItemsByDsp {
    [key: string]: { label: string; value: string }[];
}

export const CHART_TYPES_TOGGLE_GROUP_ITEMS_BY_DSP: ChartTypesToggleGroupItemsByDsp =
    {
        [STORE_ID_SPOTIFY]: SPOTIFY_CHART_TYPES_TOGGLE_GROUP_ITEMS,
        [STORE_ID_TIKTOK]: TIKTOK_CHART_TYPES_TOGGLE_GROUP_ITEMS,
        [STORE_ID_RECOCHOKU]: SHARED_CHART_TYPES_TOGGLE_GROUP_ITEMS,
        [STORE_ID_LINE]: SHARED_CHART_TYPES_TOGGLE_GROUP_ITEMS,
        [STORE_ID_AMAZON_MUSIC]: [],
        [STORE_ID_APPLE_MUSIC]: [],
        [STORE_ID_DEEZER]: [],
        [STORE_ID_SOUNDCLOUD]: [],
        [STORE_ID_SHAZAM]: [],
    };
