import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { groupBy, orderBy, reduce } from 'lodash';
import { TOP_ORDERED_STORES_IDS } from 'src/constants/metrics';
import {
    STORE_IDS,
    STORE_SOURCES,
    StoreSource,
    SourceType,
    SOURCE_STREAMS,
    SOURCE_DOWNLOADS,
} from 'src/constants/stores';
import { AnalyticsFeedsData } from '../definitions/AnalyticsFeedsData';
import { filterData, assertValue, nonNullable } from '../utils';
import AnalyticsFeedsDataQuery from './analyticsFeedsData.gql';

interface Options {
    types?: SourceType[];
    onlyStores?: number[];
    skip?: boolean;
}

export const useAnalyticsFeeds = ({
    types = [SOURCE_STREAMS, SOURCE_DOWNLOADS],
    onlyStores,
    skip,
}: Options = {}) => {
    const { data, loading, error } = useQuery<AnalyticsFeedsData>(
        AnalyticsFeedsDataQuery,
        { fetchPolicy: 'cache-first', skip }
    );

    const stores = useMemo(() => {
        const raw = filterData(data?.analyticsFeeds);
        const byStore = groupBy(raw, feed => feed.storeId?.toString() ?? '0');

        const supportedStores = reduce(
            byStore,
            (result, value, key): StoreSource[] => {
                const storeId = parseInt(key, 10);
                const isStoreAllowed =
                    !onlyStores || onlyStores.includes(storeId);
                if (!STORE_IDS.includes(storeId) || !isStoreAllowed)
                    return result;

                const [store] = value;
                const errors = filterData(
                    value.map(
                        feed =>
                            feed.error && {
                                code: nonNullable(feed.error.code),
                                message: nonNullable(feed.error.message),
                                types: filterData(feed.error.types),
                            }
                    )
                );

                return [
                    ...result,
                    {
                        storeId: assertValue(store, 'storeId'),
                        storeName: assertValue(store, 'storeName'),
                        error: errors[0],
                        errors,
                    },
                ];
            },
            [] as StoreSource[]
        ).filter(store =>
            types.some(type =>
                (STORE_SOURCES[store.storeId] ?? [SOURCE_STREAMS]).includes(
                    type
                )
            )
        );

        return orderBy(
            supportedStores,
            [
                ({ storeId }) =>
                    TOP_ORDERED_STORES_IDS.includes(storeId)
                        ? TOP_ORDERED_STORES_IDS.indexOf(storeId)
                        : TOP_ORDERED_STORES_IDS.length,
                'storeName',
            ],
            'asc'
        );
    }, [data, types, onlyStores]);

    return {
        data: stores,
        loading,
        error,
    };
};
