import { Segment } from '@theorchard/suite-frontend';
import { WORLDWIDE } from 'src/constants/countries';
import {
    ACCOUNT,
    COUNTRIES,
    SOURCE_OF_STREAMS,
    SUBACCOUNT,
} from 'src/constants/metrics';
import { PAGE_OVERVIEW } from 'src/constants/page';
import { STORES_BY_ID, STORE_ALL } from 'src/constants/stores';
import { getLocalizedName } from 'src/utils/countries';
import { getPageName, ParamValue } from 'src/utils/route';

export const COMPARISON_CATEGORY = 'Insights';
export const YOUTUBE_CATEGORY = 'Insights-Youtube';
export const SHARE = 'Share';

// Categories
const GLOBAL_FILTER_CATEGORY_NAME = 'Global Filters';
const SONG_CHARTS_FILTER_CATEGORY_NAME = 'Song Charts Filter';
const PLAYLISTS_GRAPHS_CATEGORY_NAME = 'Playlists Graphs';
const PERSONALIZATION_CATEGORY_NAME = 'Personalization';
const TOP_TERRITORIES = 'Top Territories';

// Actions / Event names
export const COUNTRY_FILTER_EVENT_NAME = 'Country Filter';
const SMART_PRODUCTS_EVENT_NAME = 'Smart Products';
export const CUSTOMIZE_COLUMNS_EVENT_NAME = 'CustomizeColumns';
export const ENGAGE_CUSTOMIZE_COLUMNS = 'Engage Customize columns selection';
export const PAGE_VISITED = 'Page Visited';
export const TABLE_SORTED = 'Table sorted';
export const CLICK_SEE_ALL = 'Click See All';
const SONG_CHARTS_CHARTS_COUNTRY_FILTER_EVENT_NAME =
    'Charts Filter; Country Filter';
const DATE_FILTER = 'Date Filter';
const PERIOD_FILTER = 'Period Filter';
const STORE_FILTER = 'Store Filter';
const STORE_MULTI_FILTER = 'Store filter multi-select';
const BRAND_FILTER = 'Brand Filter';
const ARTISTS_FILTER = 'Artists Filter';
const LABELS_FILTER = 'Labels Filter';
const LABEL_MANAGER_FILTER = 'Label Manager Filter';
export const PLAYLIST_TYPE_FILTER = 'Playlist Type Filter';
export const PLAYLIST_CATEGORY_FILTER = 'Playlist Category Filter';
export const TYPE_MULTISELECT_FILTER = 'Type multi-select filter';
export const CLICK_EXPORT_BUTTON = 'Click Export button';
export const DETAILS_POPUP_CLICKED = 'Details pop-up clicked';
export const SUMMARY_ROWS_CLICKED = 'Summary rows clicked';
const YOUTUBE_CHANNELS_FILTER = 'YouTube Channels';
export const FILTER_SEARCH_FILTER = 'Filter Search';
const PARENT_COMPANY_FILTER = 'Parent company Filter';
const SERVICE_TIER_FILTER = 'Service Tier Filter';
const TOOLTIP_ENGAGE = 'Engage Tooltip';
const INTERACT_DETAILED_VIEW = 'Interact Detailed View';
const CLICK_COMPARE_TO = 'Click Compare To';
const ENGAGEMENT = 'Engagement';
const VIEW = 'View';
const EXPORT = 'Export';
const PLAYLISTS_GRAPHS_TOGGLE = 'Toggle';
const COLLAPSE = 'Collapse';
const EXPAND = 'Expand';
export const ROWS_PER_PAGE = 'Rows per page click';
export const PAGINATION_BUTTON = 'Pagination button click';
export const ONLY_CURRENT_APPEARANCES_CLICK = 'Only Current Appearances Click';
export const CHARTS_DETAILS_VIEWED = 'Charts Details Viewed';
export const CLICK_GRAPH_TYPE = 'Click Graph Type';
export const CLICK_SHARE = 'Click Share';
export const CLICK_SHARE_TYPE = 'Click Share Type';
export const CLICK_MY_CATALOG_ONLY = 'My Catalog only click';
export const CLICK_EXACT_PHRASE = 'Exact Phrase click';
export const CHECKBOX_STATE_ON = 'On';
export const CHECKBOX_STATE_OFF = 'Off';
export const SEARCH_VIEW_EVENT_NAME = 'view search page';
export const CLICK_CHARTS_VIEW = 'Click charts view';
export const CHARTS_TYPE = 'Charts type';
export const CHARTS_SELECTED = 'Charts selected';
export const CLICK_BY_REGION = 'Click By region';
export const CLICKED_BACK_TO_TOP = 'Back to Top clicked';
export const CHARTS_TAB_CLICKED = 'Inner Tab clicked';
export const PERIOD_RANGE_SLIDER = 'Period range slider';
export const ISRC_COUNTRY = 'ISRC Country';
export const PERCENTAGE_RANGE_SLIDER = 'Percentage range slider';
export const PERIOD_SWITCHER = 'Period switcher';
export const SOCIAL_LINKS_EDIT_CLICKED = 'Social Links Edit Clicked';
export const SOCIAL_LINK_SUBMITTED = 'Social Link Submitted';
export const ENGAGE_SOURCE_POPUP = 'Engage Source pop-up';
export const STORE_STATUS_POPUP = 'Store status pop-up';
export const NMF_BY_REGION_SWITCHER = 'NMF By Region switcher';
export const CLICK_POP_UP = 'Click Pop-up';
export const FEATURED_TOGGLE = 'Featured toggle';
export const SORTING_TOGGLE = 'Sorting toggle';

// 1st and 2nd dimensions we are interested in for performance over time
const METRICS_TO_RECORD = ['potMetric', 'sourceMetric', 'potDimension'];
const PERFORMANCE_OVER_TIME = 'Performance over Time';
const POT_DIM_1 = 'POT Graph - Dimension 1';
const POT_DIM_2 = 'POT Graph - Dimension 2';
const POT_DIM_3 = 'POT Graph - Dimension 3';

// Top Territories events
export const TERRITORIES_SHOW_MORE = 'Top Territories Table - show more';
export const TERRITORIES_SHOW_LESS = 'Top Territories Table - show less';
export const TERRITORIES_STREAMS = 'Streams Dimension';
export const TERRITORIES_DOWNLOADS = 'Downloads Dimension';

// Date Picker Selection types
export const PERIOD_FILTER_LABELS = {
    CUSTOM: 'Custom',
    QUICK_SELECTION: 'Quick Selection',
};

export const INTERACTION_TYPES = {
    COPY_LINK: 'Copy Link',
    EXPORT_AS_PDF: 'Export As PDF',
};

// Filters/My Catalog
const MY_CATALOG_FILTERS = 'Filters/My Catalog';

// Labels
const EXPORT_LABEL = 'CSV';

type PotMetricOrDimension = string | Record<string, ParamValue | undefined>;
interface CompareToProperties {
    isrc?: string;
    artistId?: string;
    title?: string;
}
type InteractionParams = Record<string, string | string[] | undefined>;

export const trackMyCatalogFilterEvent = (event: string, label?: string) => {
    Segment.trackEvent(event, { category: MY_CATALOG_FILTERS }, label);
};

export const trackMyCatalogArtistFilterEvent = (artist?: string) => {
    trackMyCatalogFilterEvent(ARTISTS_FILTER, artist);
};

export const trackMyCatalogLabelsFilterEvent = (labels?: string) => {
    trackMyCatalogFilterEvent(LABELS_FILTER, labels);
};

export const trackMyCatalogYouTubeChannelsFilterEvent = (
    channelName?: string
) => {
    trackMyCatalogFilterEvent(YOUTUBE_CHANNELS_FILTER, channelName);
};

export const trackMyCatalogTermFilterEvent = () => {
    trackMyCatalogFilterEvent(FILTER_SEARCH_FILTER);
};

export const trackComparisonEvent = (event: string, label?: string) => {
    Segment.trackEvent(event, { category: COMPARISON_CATEGORY }, label);
};

export const trackYoutubeEvent = (event: string, label?: string) => {
    Segment.trackEvent(event, { category: YOUTUBE_CATEGORY }, label);
};

export const trackGlobalFiltersLabelsFilterEvent = (label?: string) => {
    Segment.trackEvent(
        LABELS_FILTER,
        { category: GLOBAL_FILTER_CATEGORY_NAME },
        label
    );
};

export const trackGlobalFiltersLabelManagerFilterEvent = (
    labelManager?: string
) => {
    Segment.trackEvent(
        LABEL_MANAGER_FILTER,
        { category: GLOBAL_FILTER_CATEGORY_NAME },
        labelManager
    );
};

export const trackShareEvent = (
    event: string,
    label?: string,
    properties?: object
) => {
    Segment.trackEvent(event, { category: SHARE, ...properties }, label);
};

export const trackGlobalCountryFilterEvent = (countryName?: string) => {
    Segment.trackEvent(
        COUNTRY_FILTER_EVENT_NAME,
        { category: GLOBAL_FILTER_CATEGORY_NAME },
        countryName
    );
};

export const trackCustomizeColumnsSelectionEvent = (
    columns: string,
    page?: string
) => {
    Segment.trackEvent(
        CUSTOMIZE_COLUMNS_EVENT_NAME,
        { category: PERSONALIZATION_CATEGORY_NAME, page },
        columns
    );
};

export const trackSmartProductsEvent = (label: string) => {
    Segment.trackEvent(
        SMART_PRODUCTS_EVENT_NAME,
        { category: GLOBAL_FILTER_CATEGORY_NAME },
        label
    );
};

export const trackDateFilterEvent = (date: string) => {
    Segment.trackEvent(DATE_FILTER, {
        date,
        category: GLOBAL_FILTER_CATEGORY_NAME,
    });
};

export const trackBrandFilterEvent = (type?: string, value?: string) => {
    Segment.trackEvent(
        type === 'brand' ? BRAND_FILTER : PARENT_COMPANY_FILTER,
        {
            category: GLOBAL_FILTER_CATEGORY_NAME,
        },
        value
    );
};

export const trackServiceTierFilterEvent = (value?: string) => {
    Segment.trackEvent(
        SERVICE_TIER_FILTER,
        { category: GLOBAL_FILTER_CATEGORY_NAME },
        value
    );
};

export const trackStoreFilterEvent = (storeId: number, tab: string) => {
    const store = STORES_BY_ID[storeId] || storeId.toString();
    const storeName = store === '0' ? STORE_ALL : store;
    Segment.trackEvent(
        STORE_FILTER,
        { tab, category: GLOBAL_FILTER_CATEGORY_NAME },
        storeName
    );
};

export const trackMultiStoreFilterEvent = (
    storeValues: number[],
    tab: string
) => {
    const storeLabels = storeValues
        .map(storeId => STORES_BY_ID[storeId])
        .join(', ');

    Segment.trackEvent(
        STORE_MULTI_FILTER,
        { tab, category: GLOBAL_FILTER_CATEGORY_NAME },
        storeLabels
    );
};

export const trackPotStoreFilterEvent = (storeId: number, params: object) => {
    const store = STORES_BY_ID[storeId] || storeId.toString();
    const storeName = store === '0' ? STORE_ALL : store;
    Segment.trackEvent(STORE_FILTER, {
        ...params,
        category: PERFORMANCE_OVER_TIME,
        url: window.location.href,
        domain: window.location.hostname,
        path: window.location.pathname,
        search: window.location.search,
        label: storeName,
    });
};

export const trackSongPageChartsChartsTypeFilterEvent = (
    chartName?: string
) => {
    Segment.trackEvent(
        SONG_CHARTS_FILTER_CATEGORY_NAME,
        { category: SONG_CHARTS_CHARTS_COUNTRY_FILTER_EVENT_NAME },
        chartName
    );
};

export const handleCountryChangeEvent = (
    segmentEventFunction: (label: string) => void,
    value?: ParamValue,
    selectedCountry?: string
) => {
    const paramValue = Array.isArray(value) ? value[0] : value;
    const isValidParamValue =
        paramValue === undefined || typeof paramValue === 'string';

    if (isValidParamValue && paramValue !== selectedCountry) {
        const countryValue = paramValue || WORLDWIDE;
        segmentEventFunction(countryValue);
    }
};

export const trackPaginationEvent = (
    event: string,
    params: InteractionParams = {}
) => {
    trackEngagementEvent(event, {
        component: 'pagination',
        ...params,
    });
};

export const trackNamedEvent = async (event: string, properties?: object) =>
    await Segment.trackEvent(event, properties, event);

export const recordPOTMetricOrDimensionChoice = (
    param: PotMetricOrDimension,
    value: undefined | ParamValue
) => {
    if (
        typeof param === 'string' &&
        METRICS_TO_RECORD.includes(param) &&
        value
    ) {
        const event = param === 'potMetric' ? POT_DIM_1 : POT_DIM_2;
        const label = typeof value === 'string' ? value : value.toString();
        Segment.trackEvent(event, { category: PERFORMANCE_OVER_TIME }, label);
    }
};

export const recordEventWithUrlParams = (name: string, params: object) => {
    Segment.trackEvent(name, {
        category: ENGAGEMENT,
        url: window.location.href,
        domain: window.location.hostname,
        path: window.location.pathname,
        name: getPageName(),
        page: getPageName(),
        search: window.location.search,
        ...params,
    });
};

export const trackCsvPotExportEvent = () => {
    Segment.trackEvent(
        EXPORT,
        { category: PERFORMANCE_OVER_TIME },
        EXPORT_LABEL
    );
};

export const trackPlaylistsGraphsToggleEvent = (isExpanded?: boolean) => {
    const label = isExpanded ? EXPAND : COLLAPSE;
    Segment.trackEvent(
        PLAYLISTS_GRAPHS_TOGGLE,
        { category: PLAYLISTS_GRAPHS_CATEGORY_NAME },
        label
    );
};

export const trackTopTerritoriesEvent = (event: string) => {
    Segment.trackEvent(event, { category: TOP_TERRITORIES });
};

export const trackPeriodFilterEvent = (
    tab: string,
    isCustomPeriod: boolean
) => {
    const { CUSTOM, QUICK_SELECTION } = PERIOD_FILTER_LABELS;

    Segment.trackEvent(PERIOD_FILTER, {
        category: GLOBAL_FILTER_CATEGORY_NAME,
        period: isCustomPeriod ? CUSTOM : QUICK_SELECTION,
        tab,
    });
};

export const trackSongPOTEngageEvent =
    (tab = PAGE_OVERVIEW) =>
    (sourceName?: string) => {
        Segment.trackEvent(TOOLTIP_ENGAGE, {
            tab,
            tooltipTitle: sourceName,
            component: 'POT',
        });
    };

export const trackDetailedViewEvent = (
    defaultDimensions: boolean,
    metric?: string,
    tab = PAGE_OVERVIEW
) => {
    Segment.trackEvent(INTERACT_DETAILED_VIEW, {
        tab,
        metric,
        defaultDimensions: defaultDimensions ? 'yes' : 'no',
    });
};

export const trackCompareModeEvent = (
    properties: CompareToProperties,
    tab = PAGE_OVERVIEW
) => {
    Segment.trackEvent(CLICK_COMPARE_TO, {
        tab,
        ...properties,
        category: ENGAGEMENT,
        component: 'Compare To',
    });
};

export const trackChartsChangeEvent = (event: string, chart: string) => {
    Segment.trackEvent(event, { chart, category: ENGAGEMENT });
};

export const trackChartsDateChange = (chart: string, date?: string) => {
    Segment.trackEvent(DATE_FILTER, {
        chart,
        date,
        category: GLOBAL_FILTER_CATEGORY_NAME,
    });
};

export const trackEngagementEvent = (
    event: string,
    params: InteractionParams = {}
) => {
    Segment.trackEvent(event, {
        ...params,
        category: ENGAGEMENT,
    });
};

export const trackViewPageEvent = (
    event: string,
    params: InteractionParams
) => {
    Segment.trackEvent(event, {
        ...params,
        category: VIEW,
    });
};

export const trackStoreSourcesPopupEvent = (params: object) => {
    Segment.trackEvent('Engage Source pop-up', {
        ...params,
        url: window.location.href,
        domain: window.location.hostname,
        path: window.location.pathname,
        category: ENGAGEMENT,
    });
};

export const trackPOTTabChangeEvent = (tab: string, params: object) => {
    let potTab = tab;
    switch (tab) {
        case SOURCE_OF_STREAMS:
            potTab = 'Streams&Sources';
            break;
        case COUNTRIES:
            potTab = 'By Countries';
            break;
    }

    Segment.trackEvent('POT Tab', {
        ...params,
        url: window.location.href,
        domain: window.location.hostname,
        potTab,
        path: window.location.pathname,
        category: PERFORMANCE_OVER_TIME,
    });
};

export const trackPotSubscriptionChangeEvent = (
    value?: string,
    properties?: object
) => {
    const path = window.location.pathname;
    const page = path.includes(SUBACCOUNT) ? SUBACCOUNT : ACCOUNT;
    Segment.trackEvent(
        POT_DIM_3,
        {
            category: PERFORMANCE_OVER_TIME,
            domain: window.location.hostname,
            name: ACCOUNT,
            page,
            path,
            search: window.location.search,
            url: window.location.href,
            ...properties,
        },
        value
    );
};

export const trackMarketSelectionEvent = (
    region: string | null,
    countryCodes: string[],
    tab: string | string[] = PAGE_OVERVIEW,
    shouldLogRecentSelection = false,
    eventName = 'Market Filter',
    marketsName = 'Markets',
    categoryName = GLOBAL_FILTER_CATEGORY_NAME
) => {
    const propertyValue = region ? region : countryCodes.map(getLocalizedName);
    let propertyName = region && region !== 'All' ? 'Region' : marketsName;

    if (shouldLogRecentSelection) {
        propertyName = 'Recent Selection';
    }

    Segment.trackEvent(eventName, {
        tab,
        [propertyName]: propertyValue,
        category: categoryName,
    });
};

export const trackSourcesEngagement = (section: string) => {
    return () => trackEngagementEvent(ENGAGE_SOURCE_POPUP, { section });
};

export const trackStoreStatusEngagement = () => {
    return trackEngagementEvent(STORE_STATUS_POPUP);
};

export default {
    trackNamedEvent,
    trackComparisonEvent,
    trackYoutubeEvent,
    trackShareEvent,
    recordPOTMetricOrDimensionChoice,
    trackDateFilterEvent,
    trackCsvPotExportEvent,
    trackPeriodFilterEvent,
    trackPlaylistsGraphsToggleEvent,
    trackSongPOTEngageEvent,
    trackDetailedViewEvent,
    trackCompareModeEvent,
    trackChartsChangeEvent,
    trackChartsDateChange,
    trackEngagementEvent,
    trackStoreSourcesPopupEvent,
    trackPotSubscriptionChangeEvent,
};
