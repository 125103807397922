import { useEffect } from 'react';
import { useFeatureFlag } from '@theorchard/suite-frontend';
import { INSIGHTS_ERROR_PAGE } from 'src/constants/featuresFlags';
import type {
    ErrorTypeContextOptions,
    ErrorTypeOptions,
    ErrorTypeResult,
} from '../types';
import { useErrorContext } from './useErrorContext';
import { useErrorType } from './useErrorType';

export const useErrorTypeUpdater = (
    options: ErrorTypeOptions,
    { name }: ErrorTypeContextOptions = {}
): ErrorTypeResult => {
    const isErrorV2Enabled = useFeatureFlag(INSIGHTS_ERROR_PAGE);
    const { setDataStatus } = useErrorContext(name);
    const { errorType } = useErrorType(options);

    useEffect(() => {
        if (!isErrorV2Enabled) return;
        setDataStatus({ errorType });
    }, [isErrorV2Enabled, errorType, setDataStatus]);

    return {
        errorType,
    };
};
