import React from 'react';
import { GridTable } from '@theorchard/suite-components';
import PlatformIcon from 'src/components/platformIcon';

const CLASSNAME = 'GridTablePlatformCell';

GridTable.defineColumnTemplate('platform', {
    align: 'left',
    sortable: false,
    Cell: ({ value, data: { storeName } }) => {
        const platform = typeof value === 'string' ? value : null;
        const storeId = typeof value === 'number' ? value : null;

        return (
            <div className={CLASSNAME}>
                <PlatformIcon platform={platform} storeId={storeId} size="16" />
                {storeName && <span>{storeName}</span>}
            </div>
        );
    },
});
