import { useQuery } from '@apollo/client';
import { useFeatureFlag } from '@theorchard/suite-frontend';
import { CompanyBrands } from 'src/apollo/definitions/CompanyBrands';
import { selectCompany } from 'src/apollo/selectors/product';
import { BRAND_ALTAFONTE, BRAND_DRM, BRAND_MASS_APPEAL } from 'src/constants';
import {
    INSIGHTS_BRAND_ALTAFONTE,
    INSIGHTS_NEW_BRANDS,
} from 'src/constants/featuresFlags';
import { flattenError } from '../../utils';
import companyBrandsQuery from './companyBrands.gql';

export const useCompanyBrandsQuery = (skip?: boolean) => {
    const isMaDrmEnabled = useFeatureFlag(INSIGHTS_NEW_BRANDS);
    const isAltafonteBrandEnabled = useFeatureFlag(INSIGHTS_BRAND_ALTAFONTE);
    const { data, loading, error } = useQuery<CompanyBrands>(
        companyBrandsQuery,
        { fetchPolicy: 'cache-first', skip }
    );

    return {
        loading,
        data: selectCompany(data, {
            [BRAND_MASS_APPEAL]: isMaDrmEnabled,
            [BRAND_DRM]: isMaDrmEnabled,
            [BRAND_ALTAFONTE]: isAltafonteBrandEnabled,
        }),
        error: error && flattenError(error),
    };
};
