import React, { FC } from 'react';
import { Button, InfoMessage } from '@theorchard/suite-components';
import { formatMessage } from '@theorchard/suite-frontend';
import { CLASSNAME as ERROR_CLASSNAME } from 'src/components/errorV2/error';
import { SharedErrorV2Props } from 'src/components/errorV2/types';

const ErrorPage: FC<SharedErrorV2Props> = ({ mode }) => {
    if (mode === 'tinySection') {
        return <>{formatMessage('error.dataFailed.message')}</>;
    }

    return (
        <InfoMessage
            illustration="dataError"
            size={mode === 'section' ? 'sm' : 'lg'}
            message={formatMessage('error.fatal.title')}
            body={
                <>
                    <span>{formatMessage('error.dataFailed.message')}</span>
                    <Button
                        className={`${ERROR_CLASSNAME}-actionBtn`}
                        variant={
                            mode === 'section' ? 'quaternary' : 'secondary'
                        }
                        onClick={() => window.location.reload()}
                    >
                        {formatMessage('error.errorPage.refresh')}
                    </Button>
                </>
            }
        />
    );
};

export default ErrorPage;
