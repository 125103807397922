import { FunctionComponent } from 'react';
import {
    DataError,
    NoDataError,
    NoResultsCatalogError,
    NoResultsError,
    NotFoundError,
} from './components';
import type { ErrorType, SharedErrorV2Props } from './types';

export const PAGE_CONFIG: Record<
    ErrorType,
    FunctionComponent<SharedErrorV2Props>
> = {
    dataError: DataError,
    noData: NoDataError,
    notFound: NotFoundError,
    noResults: NoResultsError,
    noResultsInCatalog: NoResultsCatalogError,
};
